import React, { useEffect, useState } from "react";
import { Form, Button, PageHeader, Breadcrumb, Divider, Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { TabletFilled } from "@ant-design/icons";
import history from "../../../history";
import {
  renderCheckbox,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import { connect } from "react-redux";
import { getRoleById, updateRole } from "../../../actions/role";

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const auth = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const EditRole = ({ getRoleById, updateRole, location }) => {
  const [data, setData] = useState(null);
  const [roleId, setRoleId] = useState(null);

  const onFinish = (values) => {
    updateRole(values, roleId);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0)
    const search = location.search;
    const id = new URLSearchParams(search).get("id");
    setRoleId(id);
    const res = await getRoleById(id);
    setData(res);
    form.setFieldsValue({
      role: res?.name,
      active: res?.active,
      user_management: res?.access?.user_management,
      driver_management: res?.access?.driver_management,
      warehouse_management: res?.access?.warehouse_management,
      order_management: res?.access?.order_management,
      finance_management: res?.access?.finance_management,
      merchant_management: res?.access?.merchant_management,
      message_management: res?.access?.message_management,
      area_pricing_management: res?.access?.area_pricing_management,
    });
  }, []);

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Edit Role"
      subTitle="Admin Management"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<TabletFilled />}
          onClick={() => history.push("/rolelist")}
        >
          Role List
        </Button>,
      ]}
    ></PageHeader>
  );

  const EditRoles = (
    <>
    
      {data ? (
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={form}
        >
          {renderInput("role", "Role Name", [
            { required: true, message: "Role name is required" },
          ])}
          <Divider orientation="center">Assign role's access</Divider>

          <Row>
            <Col flex="1 1 200px">
              {renderCheckbox("user_management", "User Management", [
                { required: false },
              ])}
              {renderCheckbox("driver_management", "Driver Management", [
                { required: false },
              ])}
              {renderCheckbox("warehouse_management", "WareHouse Management", [
                { required: false },
              ])}
              {renderCheckbox("order_management", "Order Management", [
                { required: false },
              ])}
            </Col>
            <Col flex="1 1 200px">
              {renderCheckbox("finance_management", "Finance Management", [
                { required: false },
              ])}

              {renderCheckbox("merchant_management", "Merchant Management", [
                { required: false },
              ])}
              {renderCheckbox("message_management", "Support Management", [
                { required: false },
              ])}
              {renderCheckbox(
                "area_pricing_management",
                "Area Price Management",
                [{ required: false }]
              )}
            </Col>
          </Row>
          {renderSelect(
            "active",
            "Auth",
            [{ required: true }],
            "Select a option and change input text above",
            auth,
            "none"
          )}
          {renderTextField("remark", "Remark", [
            { required: true, message: "Remark is required" },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </>
  );
  return (
    <Content>
      {Pageheader}<br></br>
      {EditRoles}
    </Content>
  );
};

export default connect(null, { getRoleById, updateRole })(EditRole);
