import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ParcelStatistics } from "../../../actions/parcel";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const ParcelDashboardS = ({ ParcelStatistics }) => {
  const [data, setData] = useState([]);
  

  useEffect(async () => {
    window.scrollTo(0, 0)
    const parcel_statistic = await ParcelStatistics();

    const piechartData = [];

    
    if(parcel_statistic?.statistic[0]?.totalOrderStatus?.length !== 0){

      piechartData.push(
        {
          type: "Return",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.return,
        },
        {
          type: "Delivered",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.delivered,
        },
        {
          type: "Cancel",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.cancel,
        },
        {
          type: "Pending",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.pending,
        }
      );

    }

    setData(piechartData);

    

    
  }, []);

 

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    color: ['#231a52', '#3ab569', '#b91e1e',"#96968c"],
    // 自定义状态样式
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    // 添加 element 选中和激活交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const Total_statistic = <Pie {...config} />;

 


 

  return (
    <Content>
     
      {Total_statistic}
      
    </Content>
  );
};

export default connect(null, {
  ParcelStatistics,
})(ParcelDashboardS);
