import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,
  Descriptions,
  Divider,
  Card,
} from "antd";

import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { searchMerchantShop } from "../../../actions/merchant";
import history from "../../../history";

const { RangePicker } = DatePicker;
const SearchMerchant = ({ searchMerchantShop }) => {
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [shops, setShops] = useState([]);
  const [merchant, setMerchant] = useState([]);

  const Refresh = async () => {
    const res = await searchMerchantShop();

    setData(res);
  };
  const handleVisible = async (mobile_number, shop_id) => {
   
    history.push(`/shop-details?merchant=${mobile_number}&shop_id=${shop_id}`);
  };
  const handlemerchantSearch = async (e) => {
    window.scrollTo(0, 0)
    setNumber(e?.targe?.value);

    let value = e?.target?.value;

    if (value?.length === 11) {
      const res = await searchMerchantShop(value);
      setShops(res?.shops);
      delete res?.shops;
      setMerchant(res);
      setData(value);
    }
    if (value?.length > 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
    if (value?.length < 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "20%",
    },
    {
      title: "Balance",
      dataIndex: "account_balance",
      key: "account_balance",
      width: "18%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "10%",
    },
  ];
  const createData = (
    name,
    mobile_number,
    address,
    account_balance,

    action
  ) => {
    return {
      name,
      mobile_number,
      address,
      account_balance,

      action,
    };
  };

  const rows = [
    merchant.success
      ? merchant?.merchantShops[0]?.shops?.map((Shop, id) =>
          createData(
            Shop?.name,
            Shop?.mobile_number,
            Shop?.address,
            Shop?.account_balance,

            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible(Shop?.mobile_number, Shop?.shop_id);}}
            >
              View
            </Button>
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Merchant Shop List"
      subTitle="Finance Management"
      extra={[]}
    ></PageHeader>
    <br></br>
    </>
  );
  const TABLE = (
    <>
      {merchant?.success ? (
        <>
          <Divider>Merchant Shop Details</Divider>
          <Table
            columns={columns}
            dataSource={rows[0]}
            scroll={{ x: 1000 }}
            sticky
          />
        </>
      ) : null}
    </>
  );
  const SMS = (
    <>
      <Descriptions
        title="Search Mearchant"
        justify="center"
        labelStyle={{
          marginLeft: "150px",
        }}
      >
        <Descriptions.Item
          bordered={true}
          label="Merchant Mobile Number"
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
        >
          <Input
            variant="filled"
            name="merchant"
            label="Merchant Mobile Number"
            style={{ width: "50%" }}
            onChange={(e) => handlemerchantSearch(e)}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  const MD = (
    <>
      {" "}
      {merchant?.success ? (
        <>
          <Divider>Merchant Details</Divider>
          
          <Row gutter={16} justify="center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              style={{ textAlign: "center" }}
            >
              <Card
                title="Name"
                style={{
                  textAlign: "center",
                  backgroundColor: "#b91e1e",
                  color: "white",
                }}
                bordered={false}
              >
                <p>{merchant?.merchantShops[0]?.name}</p>
              </Card>
              <br></br>
            </Col>
            <br></br>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              style={{ textAlign: "center" }}
            >
              <Card
                title="Mobile"
                style={{
                  textAlign: "center",
                  backgroundColor: "#b91e1e",
                  color: "white",
                }}
                bordered={false}
              >
                <p>{merchant?.merchantShops[0]?.mobile_number}</p>
              </Card>
              <br></br>
            </Col>
            <br></br>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              style={{ textAlign: "center" }}
            >
              <Card
                title="Address"
                style={{
                  textAlign: "center",
                  backgroundColor: "#b91e1e",
                  color: "white",
                }}
                bordered={false}
              >
                <p>{merchant?.merchantShops[0]?.address}</p>
              </Card>
              <br></br>
            </Col>
            <br></br>
          </Row>
        </>
      ) : null}
    </>
  );
  return (
    <Content>
      {window.scrollTo(0, 0)}
      {Pageheader}
      {SMS}
      {console.log('data',data)}
      {data.length > 11 ? (
        <h1 style={{ textAlign: "center" }}>Invalid Mobile Number</h1>
      ) : data.length ===11?(
        <>
          {MD}
          {TABLE}
        </>
      ): data.length <11 && data.length >1 ?
      <h1 style={{ textAlign: "center" }}>Insert 11 Digit Mobile Number</h1>
      :null
      }
    </Content>
  );
};

export default connect(null, { searchMerchantShop })(SearchMerchant);
