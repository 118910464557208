import { EyeOutlined, SearchOutlined ,DownloadOutlined,EditOutlined,RollbackOutlined,ExclamationCircleOutlined} from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,Modal,Form
} from "antd";
import "./style.css";
import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ParcelBySearch,makeReturn,deleteParcel,getParcelReport } from "../../../actions/parcel";
import history from "../../../history";
import { renderSelect, renderTextField } from "../../../components/form";

const { RangePicker } = DatePicker;
const SearchParcel = ({ ParcelBySearch,makeReturn,deleteParcel,getParcelReport, }) => {
  const [data, setData] = useState('');
  const [page, setpage] = useState(1);
  const [id, setId] = useState("");
  const [moi, setMoi] = useState(null);
  const [oi, setOi] = useState(null);
  const [m, setM] = useState(null);
  const [s, setS] = useState(null);
  const [rmn, setRmn] = useState(null);
  const [t, setT] = useState(null);
  const [dt, setDt] = useState(null);
  const [ti, setTi] = useState(null);
  const [st, setSt] = useState(null);
  const [ds, setDs] = useState(null);
  const [de, setDe] = useState(null);
  const [c, setC] = useState(1);
  const [returnorder, setReturnOrder] = useState(false);
  const [cancelorder, setCancelOrder] = useState(false);
  const [form] = Form.useForm();
  const [rshopid, setRShopId] = useState("");
  const [rmobilenumber, setRMobileNumber] = useState("");
  const [rrecipientmobilenumber, setRRecipientMobileNumber] = useState("");
  const [rorderid, setROrderId] = useState("");

  const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    
  }, []);

  const handleApiSearch = async () => {
    const res = await ParcelBySearch(
      c,
      moi,
      oi,
      m,
      s,
      rmn,
      t,
      dt,
      ti,
      st,
      ds,
      de
    );
    setpage(c)
    setData(res);
  };
  const onFinishFailedReturn = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const handleApiDownload = async () => {
    const res = await getParcelReport(
      
      moi,
      oi,
      m,
      s,
      rmn,
      t,
      dt,
      ti,
      st,
      ds,
      de
    );
    setpage(c)
    setData(res);
  };
  const onFinishReturn = async (remark) => {
  
    const code = await makeReturn( rshopid,rmobilenumber, rrecipientmobilenumber,rorderid,remark);

    if (code === 201) {
      const res = await ParcelBySearch(
        page,
        moi,
        oi,
        m,
        s,
        rmn,
        t,
        dt,
        ti,
        st,
        ds,
        de
      );
      setData(res);
      form.resetFields();
     
     
    }
    setReturnOrder(false);
  };
  const onFinishCancel = async (remark) => {
  
    const code = await deleteParcel( rshopid,rmobilenumber, rrecipientmobilenumber,rorderid,remark);
     
    if (code === 201) {
      const res = await ParcelBySearch(
        page,
        moi,
        oi,
        m,
        s,
        rmn,
        t,
        dt,
        ti,
        st,
        ds,
        de
      );
      setData(res);
      form.resetFields();
     
     
    }
    setCancelOrder(false);
  };
  const handlePageChange = async (e) => {
  
    const res = await ParcelBySearch(
      e,
      moi,
      oi,
      m,
      s,
      rmn,
      t,
      dt,
      ti,
      st,
      ds,
      de
    );
    setpage(e)
    setData(res);
    window.scrollTo(0, 0);
  };
  const handleOkReturn = () => {
    setReturnOrder(false);
  };
  const handleCancelReturn = () => {
    setReturnOrder(false);
  };
  const handleDate = (date) => {
    if (date) {
      let ds = new Date(date[0]._d);
      let de = new Date(date[1]._d);
      // setDs(ds.toLocaleDateString());
      // setDe(de.toLocaleDateString());
      setDs(ds.toISOString().split('T')[0]);
      setDe(de.toISOString().split('T')[0]);
    } else {
      setDs(null);
      setDe(null);
      return;
    }
  };
  const handleVisible = async (mobile, order, shop) => {
    history.push(
      `/parcel-details?merchant=${mobile}&order=${order}&shop=${shop}`
    );
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "18%",
    },
    {
      title: "Customer Info",
      dataIndex: "recipient_mobile_number",
      key: "recipient_mobile_number",
      width: "18%",
    },
    {
      title: "Payment Info",
      dataIndex: "item_actual_price",
      key: "item_actual_price",
      width: "18%",
    },
    {
      title: "Merchant Info",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "18%",
    },
    {
      title: "Store Info",
      dataIndex: "shop_id",
      key: "shop_id",
      width: "18%",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: "14%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "14%",
    },
  ];
  const createData = (
    order_id,
    recipient_mobile_number,
    item_actual_price,
    mobile_number,
    shop_id,
    type,
    state,

    action
  ) => {
    return {
      order_id,
      recipient_mobile_number,
      item_actual_price,
      mobile_number,
      shop_id,
      type,
      state,
      action,
    };
  };

  const rows = [
    data
      ? data?.orders?.map((Shop, id) =>
          createData(
            [
              <>
                {Shop?.order_id} ;<br></br>
                {moment.utc(Shop.created_at).format("YYYY-MM-DD hh:mm A")};
                <br></br>
              </>,
            ],
            [
              <>
                {Shop?.recipient_name} ;{Shop?.recipient_address} ;<br></br>
                {Shop?.recipient_mobile_number} ;<br></br>
              </>,
            ],
            [
              <>
                {Shop.type == "NO"
                  ? `Delivery Charge:${Shop?.charges?.delivery_charge};\n`
                  : null}
                {Shop.type == "NO"
                  ? `Paybale Amount:${Shop?.charges?.payable_amount};\n`
                  : null}
                {Shop.type != "NO"
                  ? `Return Charge:${Shop?.charges?.return_charge};\n`
                  : null}
                {Shop.type == "NO"
                  ? `Amount to Collect:${Shop?.charges?.amount_to_collect};\n`
                  : null}
                {Shop.type == "NO"
                  ? `Actual Price:${Shop?.charges?.item_actual_price};\n`
                  : null}
                {Shop.type == "NO"
                  ? `Additional Cost:${Shop?.charges?.additional_cost};\n`
                  : null}
                {Shop.type == "NO" ? `COD:${Shop?.charges?.cod};` : null}
              </>,
            ],
            [
              <>
                {Shop?.merchant?.name} ;{Shop?.merchant?.address} ;<br></br>
                {Shop?.merchant?.mobile_number} ;<br></br>
              </>,
            ],
            [
              <>
                {Shop?.shop?.name} ;{Shop?.shop?.address} ;{Shop?.shop?.shop_id}
                ;<br></br>,
              </>,
            ],
            Shop.type,
            [
              <>
                {Shop?.state === "Pending" ? (
                  <strong
                    style={{
                      backgroundColor: "#E5E4E4",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Confirmed" ? (
                  <strong
                    style={{
                      backgroundColor: "#7CFC00",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Delivered" ? (
                  <strong
                    style={{
                      backgroundColor: "#00FFFF",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "In-Transit" ? (
                  <strong
                    style={{
                      backgroundColor: "#ABCDFF",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Out-For-Delivery" ? (
                  <strong
                    style={{
                      backgroundColor: "#ABCDFF",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Partially Delivered" ? (
                  <strong
                    style={{
                      backgroundColor: "#5F9EA0",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Returned" ? (
                  <strong
                    style={{
                      backgroundColor: "#8B0000",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "On Hold (Driver)" ? (
                  <strong
                    style={{
                      backgroundColor: "#FF8C00",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "On Hold (Warehouse)" ? (
                  <strong
                    style={{
                      backgroundColor: "#FF8C00",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Canceled" ? (
                  <strong
                    style={{
                      backgroundColor: "red",
                      color: "black",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : Shop?.state === "Picked" ? (
                  <strong
                    style={{
                      backgroundColor: "#90EE90",
                      color: "white",
                      padding: "5px",
                      borderRadius: "10%",
                    }}
                  >
                    {Shop?.state}
                  </strong>
                ) : null}{" "}
                <br></br>
              </>,
            ],
            <>
            {/* {Shop?
              <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  handleVisible(
                    Shop?.merchant?.mobile_number,
                    Shop?.order_id,
                    Shop?.shop?.shop_id
                  );
                }}
              >
                Edit
              </Button>:null} */}
              <br></br><p></p>
              <Button
                size="small"
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => {
                  handleVisible(
                    Shop?.merchant?.mobile_number,
                    Shop?.order_id,
                    Shop?.shop?.shop_id
                  );
                }}
              >
                View
              </Button>
             <p></p>
              
              {
              Shop.type == "NO"?
              <>
              
              { 
                Shop?.state === "Picked" || Shop?.state === "On Hold (Warehouse)" 
              ?
                  <Button
                  size="small"
                  type="primary"
                  icon={<RollbackOutlined />}
                  onClick={() => {
                  setReturnOrder(true);
                  setRShopId(Shop?.shop?.shop_id);
                  setROrderId(Shop?.order_id);
                  setRMobileNumber( Shop?.merchant?.mobile_number);
                  setRRecipientMobileNumber(Shop?.recipient_mobile_number);


                  }}
                >
                  Return 
                  </Button>
              :
                Shop?.state === "Confirmed" || Shop?.state === "Pending"
              ? 
                  <Button
                  size="small"
                  type="primary"
                  icon={<ExclamationCircleOutlined />}
                  onClick={() => {
                    setCancelOrder(true);
                    setRShopId(Shop?.shop?.shop_id);
                    setROrderId(Shop?.order_id);
                    setRMobileNumber( Shop?.merchant?.mobile_number);
                    setRRecipientMobileNumber(Shop?.recipient_mobile_number)


                  }}
                >
                  Cancel 
                  </Button>

              : null

              }
              </> 
              :null}
              
            </>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Search List"
      subTitle="Parcel Management"
      extra={[
        <Tooltip placement="topLeft">
          <strong
            style={{
              color: "white",
              backgroundColor: "#b91e1e",
              padding: "5px",
            }}
          >
           Total <strong> {data?.count}</strong> data found
          </strong>
        </Tooltip>,
      ]}
    ></PageHeader>
  );
  const Return_order = (
    <>
      <Modal
        title={"Return order"}
        centered
        visible={returnorder}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelReturn}
        height={500}
      >
        
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishReturn}
            onFinishFailed={onFinishFailedReturn}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        
      </Modal>
    </>
  );
  const Cancel_order  = (
    <>
      <Modal
        title="Cancel Order"
        centered
        visible={cancelorder}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setCancelOrder(false)}
        height={500}
      >
        
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishCancel}
            onFinishFailed={onFinishFailedReturn}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        
      </Modal>
    </>
  );

  const TABLE = (
    <>
      <Row>
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Merchant ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setMoi(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Order  ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setOi(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Merchant Mobile"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setM(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Shop ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setS(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Customer Mobile"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setRmn(e.target.value)}
          />
        </Col>
       
        
        <br />
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Delivery type"
            name="delivery_time"
            optionFilterProp="children"
            onChange={(e) => setDt(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="ND">Normal Delivery</Option>
            <Option value="ODD">Express Delivery</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select 
            showSearch
            style={{ width: "99%" }}
            placeholder="Select Time"
            name="time"
            optionFilterProp="children"
            onChange={(e) => setTi(e)}
            filterOption={(input, option) =>
              option.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="CD">Created Date</Option>
            <Option value="LD">Last Date</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Select a State"
            name="State"
            optionFilterProp="children"
            onChange={(e) => setSt(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Confirmed">Confirmed</Option>
            <Option value="Picked">Picked</Option>
            <Option value="In-Transit">In-Transit</Option>
            <Option value="Out-For-Delivery">Out-For-Delivery</Option>
            <Option value="Delivered">Delivered</Option>
            <Option value="Partially Delivered">Partially Delivered</Option>
            <Option value="Returned">Returned</Option>
            <Option value="On Hold (Driver)">On Hold(Driver)</Option>
            <Option value="On Hold (Warehouse)">On Hold(WareHouse)</Option>
            <Option value="Canceled">Canceled</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Select
            showSearch
            style={{ width: "99%" }}
            placeholder="Order Type"
            name="type"
            optionFilterProp="children"
            onChange={(e) => setT(e)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">None</Option>
            <Option value="NO">Normal Order</Option>
            <Option value="RO">Return Order</Option>
          </Select>
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>
       
        <Tooltip title="search" onClick={handleApiSearch}>
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </Tooltip>&nbsp; &nbsp;
        <Tooltip title="download" onClick={handleApiDownload}>
          <Button type="primary" shape="circle" icon={<DownloadOutlined />} />
        </Tooltip>&nbsp; &nbsp;
      </Row>
      {Return_order}
      {Cancel_order}
      <br></br>
      <Pagination
        size="small"
        
        current={page}
        total={data?.count}
        pageSize="50"
        onChange={handlePageChange}
        pageSizeOptions={[50]}
      /><br></br>
      <Table
        // rowClassName="red"
        // rowClassName={(record) => console.log(record?.type == 'NO') }
        hoverable={false}
        rowClassName={(record) =>
          record?.type == "NO" ? "table-row-light" : "red"
        }
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
        pagination={false}
      />
      <Pagination
        size="small"
        current={page}
        total={data?.count}
        pageSize="50"
        onChange={handlePageChange}
        pageSizeOptions={[50]}
      />
    </>
  );
  return (
    <Content>
      {Pageheader}
      <br></br>

      {TABLE}
    </Content>
  );
};

export default connect(null, { ParcelBySearch,makeReturn,deleteParcel,getParcelReport })(SearchParcel);
