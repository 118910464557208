import {
  Button,
  Form,
  Modal,
  Divider,
  Descriptions,
  PageHeader,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  Card,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { addArea, getAreaModel } from "../../../actions/area";
import { getAreas } from "../../../actions/area";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderDate,
  renderTextField,
} from "../../../components/form";
import history from "../../../history";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 12, span: 2 },
};

const status = [
  { value: "PD", label: "Point Delivery" },
  { value: "HD", label: "Home Delivery" },
];

const AddModel = ({ addArea, getAreaModel, getAreas }) => {
  const [addcitymodal, setAddCityModal] = useState(false);
  const [addsubcitymodal, setAddSubCityModal] = useState(false);
  const [addthanamodal, setAddThanaModal] = useState(false);
  const [addareamodal, setAddAreaModal] = useState(false);
  const [areaModel, setAreaModel] = useState(null);

  const [flag, setFlag] = useState(false);
  const [formcity] = Form.useForm();
  const [formsubcity] = Form.useForm();
  const [formthana] = Form.useForm();
  const [formarea] = Form.useForm();

  const [coverageArea, setCoverageArea] = useState(null);

  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);

  useEffect(async () => {
    window.scrollTo(0, 0);

    const res = await getAreas();
    setCoverageArea(res);
    setFlag(true);
  }, []);
  // console.log(city)
  // console.log(subCity)
  // console.log(thana)

  const city_ = async (event) => {
    const cityCode = event.split("-")[0];
    // console.log(cityCode)
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setsubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    formcity.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    formsubcity.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    formthana.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    formarea.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event.split("-")[0];
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    formsubcity.setFieldsValue({
      thana: "",
      area: "",
    });
    formthana.setFieldsValue({
      thana: "",
      area: "",
    });
    formarea.setFieldsValue({
      thana: "",
      area: "",
    });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event.split("-")[0];
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    formsubcity.setFieldsValue({
      area: "",
    });
    formthana.setFieldsValue({
      area: "",
    });
    formarea.setFieldsValue({
      area: "",
    });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event.split("-")[0];
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    formsubcity.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    formthana.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    formarea.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);
  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id + "-" + item.name}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id + "-" + item.name}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id + "-" + item.name}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id + "-" + item.name}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  const createOption = (label, value) => {
    return { label, value };
  };

  const options = [
    areaModel
      ? areaModel.map((item) => createOption(item.name, item.id))
      : null,
  ];

  const onFinishCity = async (values) => {
    // console.log(values)
    const code = await addArea(values, "city");

    if (code === 201) {
      formcity.resetFields();
      setAddCityModal(false);
      const res = await getAreas();
      setCoverageArea(res);
      setFlag(true);
    }
  };
  const onFinishSubCity = async (values) => {
    // console.log(values);
    const code = await addArea(values, "sub_city");

    if (code === 201) {
      formsubcity.resetFields();
      setAddSubCityModal(false)
      const res = await getAreas();
      setCoverageArea(res);
      setFlag(true);
    }
  };

  const onFinishThana = async (values) => {
    const code = await addArea(values, "thana");

    if (code === 201) {
      formthana.resetFields();
      setAddThanaModal(false)
      const res = await getAreas();
      setCoverageArea(res);
      setFlag(true);
    }
  };
  const onFinish = async (values) => {
    const code = await addArea(values, "area");

    if (code === 201) {
      formarea.resetFields();
      setAddAreaModal(false)
      const res = await getAreas();
      setCoverageArea(res);
      setFlag(true);
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const handleOkCity = async () => {
    setAddCityModal(false);
  };

  const handleCancelCity = () => {
    setAddCityModal(false);
  };
  const handleOkSubCity = () => {
    setAddSubCityModal(false);
  };

  const handleCancelSubCity = () => {
    setAddSubCityModal(false);
  };
  const handleOkThana = () => {
    setAddThanaModal(false);
  };

  const handleCancelThana = () => {
    setAddThanaModal(false);
  };
  const handleOkArea = () => {
    setAddAreaModal(false);
  };

  const handleCancelArea = () => {
    setAddAreaModal(false);
  };

  const CityModal = (
    <>
      <Modal
        title="Create City"
        centered
        visible={addcitymodal}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelCity}
        height={500}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinishCity}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={formcity}
        >
          {renderInput("city", "City", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}

          <Form.Item {...tailLayout}>
            <>
              <Button type="primary" htmlType="submit">
                Add City
              </Button>
            </>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
  const SubCityModal = (
    <>
      <Modal
        title="Create Sub City"
        centered
        visible={addsubcitymodal}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelSubCity}
        height={500}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinishSubCity}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={formsubcity}
        >
          <Form.Item
            name="city"
            label={"Select City"}
            rules={[
              {
                required: true,
                message: "City  is required",
              },
            ]}
          >
            <Select
              showSearch
              placeholder={"Select one city"}
              onChange={(e) => city_(e)}
            >
              {/* {options} */}
              {c_o}
            </Select>
          </Form.Item>
          {/* ================ Sub City ============ */}

          {renderInput("sub_city", "Sub City", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add Sub City
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
  const ThanaModal = (
    <>
      <Modal
        title="Create Thana"
        centered
        visible={addthanamodal}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelThana}
        height={500}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinishThana}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={formthana}
        >
          <Form.Item
            name="city"
            label={"Select City"}
            rules={[
              {
                required: true,
                message: "City  is required",
              },
            ]}
          >
            <Select
              showSearch
              placeholder={"Select one city"}
              onChange={(e) => city_(e)}
            >
              {/* {options} */}
              {c_o}
            </Select>
          </Form.Item>
          {/* ================ Sub City ============ */}
          {subCityArr ? (
            <Form.Item
              name="subCity"
              label={"Select Subcity"}
              rules={[
                {
                  required: true,
                  message: "Subcity is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one subcity"}
                onChange={(e) => sub_city(e)}
              >
                {/* {options} */}
                {s_o}
              </Select>
            </Form.Item>
          ) : null}
          {renderInput("thana", "Thana", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}
          {renderInput("post_code", "Post Code", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add Sub City
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
  const AreaModal = (
    <>
      <Modal
        title="Create Area"
        centered
        visible={addareamodal}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelArea}
        height={600}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={formarea}
        >
          <Form.Item
            name="city"
            label={"Select City"}
            rules={[
              {
                required: true,
                message: "City  is required",
              },
            ]}
          >
            <Select
              showSearch
              placeholder={"Select one city"}
              onChange={(e) => city_(e)}
            >
              {/* {options} */}
              {c_o}
            </Select>
          </Form.Item>
          {/* ================ Sub City ============ */}
          {subCityArr ? (
            <Form.Item
              name="subCity"
              label={"Select Subcity"}
              rules={[
                {
                  required: true,
                  message: "Subcity is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one subcity"}
                onChange={(e) => sub_city(e)}
              >
                {/* {options} */}
                {s_o}
              </Select>
            </Form.Item>
          ) : null}

          {/* ============== Thana ============== */}
          {thanaArr ? (
            <Form.Item
              name="thana"
              label={"Select one thana"}
              rules={[
                {
                  required: true,
                  message: "Thana is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one subcity"}
                onChange={(e) => thana_(e)}
              >
                {/* {options} */}
                {t_o}
              </Select>
            </Form.Item>
          ) : null}

          {renderSelect(
            "OptionType",
            "Delivery Type",
            [
              {
                required: true,
                message: "This field is required!",
              },
            ],
            "Select a option",
            status,
            "none"
          )}
          {renderInput("area", "Area", [
            { required: true, message: "This field is required!" },
          ])}
          {renderInput("ii_post_code", "Post Code", [
            { required: true, message: "This field is required!" },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add Area
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const FORM = (
    <>
      <Divider orientation="center">Add New Area</Divider>

      <Row
        gutter={20}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Col xs={6} lg={6} md={12} sm={24} style={{ marginTop: "10px" }}>
          <Card hoverable style={{ backgroundColor: "#231a52" }}>
            <Button type="primary" onClick={() => setAddCityModal(true)}>
              Add City
            </Button>
          </Card>
        </Col>

        <Col xs={6} lg={6} md={12} sm={24} style={{ marginTop: "10px" }}>
          <Card hoverable style={{ backgroundColor: "#231a52" }}>
            <Button type="primary" onClick={() => setAddSubCityModal(true)}>
              Add Sub City
            </Button>
          </Card>
        </Col>

        <Col xs={6} lg={6} md={12} sm={24} style={{ marginTop: "10px" }}>
          <Card hoverable style={{ backgroundColor: "#231a52" }}>
            <Button type="primary" onClick={() => setAddThanaModal(true)}>
              Add Thana
            </Button>
          </Card>
        </Col>

        <Col xs={6} lg={6} md={12} sm={24} style={{ marginTop: "10px" }}>
          <Card hoverable style={{ backgroundColor: "#231a52" }}>
            <Button type="primary" onClick={() => setAddAreaModal(true)}>
              Add Area
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Add Area"
      subTitle="Area Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      {FORM}
      {CityModal}
      {SubCityModal}
      {ThanaModal}
      {AreaModal}
    </Content>
  );
};

export default connect(null, {
  addArea,
  getAreaModel,
  getAreas,
})(AddModel);
