import React, { useEffect } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import {
  getDriverById,
  getTotalStac,
  getOngoing,
  updateDriverPass,
  getDriverEditData,
  updateDriver,
} from "../../../actions/Driver";
import { getWarehouses } from "../../../actions/warehouse";
import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import {
  CheckCircleOutlined,
  CheckSquareFilled,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import {
  renderDate,
  renderInput,
  renderPasswordField,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import { Title, Wrapper } from "../../../Style";
import moment from "moment";
import history from "../../../history";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

const DriverDetails = ({
  location,
  getDriverById,
  getOngoing,
  getTotalStac,
  updateDriverPass,
  getDriverEditData,
  updateDriver,
  getWarehouses,
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [ID, setId] = useState([]);
  const [passVisible, setPassVisible] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();

  const user_id = new URLSearchParams(location.search).get("user_id");
  async function fetchData() {
    const res = await getDriverById(user_id);
    const res1 = await getOngoing(user_id);
    const res2 = await getTotalStac(user_id);
    const res3 = await getDriverEditData(user_id);
    const res4 = await getWarehouses();

    setData(res);
    setData1(res1);
    setData2(res2);
    setData3(res3);
    setWarehouse(res4);
    form.setFieldsValue({
      user_id: res3?.user_id,
      name: res3?.name,
      email: res3?.email,
      mobile_number: res3?.mobile_number,
      permanent_address: res3?.permanent_address,
      present_address: res3?.present_address,
      pickup_commission: res3?.pickup_commission,
      delivery_commission: res3?.delivery_commission,
      odd_commission: res3?.odd_commission,
      salary: res3?.salary,
      warehouse_id: res3?.warehouse?.warehouse_id,
      remark: res3?.remark,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const createPlace = (label, value) => {
    return { label, value };
  };
  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);
  };
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  let option = [];
  option = warehouse
    ? warehouse.map((item) => createPlace(item.name, item.warehouse_id))
    : null;
  const onFinishEdit = async (values) => {
    const code = await updateDriver(values);
    form.setFieldsValue({
      password: code?.password,
      remark: code?.remark,
    });
    if (code === 201) {
      setVisibleEdit(false);
      fetchData();
    }
  };
  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };
  const onFinishFailedEdit = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  const onFinish = async (values) => {
    const code = await updateDriverPass(values, user_id);

    if (code === 201) {
      setPassVisible(false);
      forms.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setPassVisible(false);
  };

  const handleCancel = () => {
    setPassVisible(false);
  };
  const gridStyle = {
    width: "25%",
    textAlign: "center",
    backgroundColor: "#b91e1e",
    color: "white",
  };
  const chnagepass = (
    <>
      <Modal
        title="Change Manager Password"
        centered
        visible={passVisible}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        height={500}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={forms}
          >
            {renderPasswordField("password", "New Password", [
              { required: true, message: "This field is required" },
              {
                pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/,
                message:
                  "Minimum 8 character, allowed special characters are @$!%*?&",
              },
            ])}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
  const driveredit = (
    <>
      <Modal
        title="Driver Edit"
        centered
        visible={visibleedit}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelEdit}
        height={500}
      >
        {data3 ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailedEdit}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Divider orientation="center">Driver Information </Divider>

            {renderInput("user_id", "Driver Id", [
              { required: true, message: "This field is required!" },
              { pattern: /^(D)[0-9]{6}$/, message: " Ex D220001 D220002" },
            ])}
            {renderInput("name", "Full Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("email", "Email", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("mobile_number", "Mobile Number", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}

            {renderTextField("permanent_address", "Permanent Address", [
              { required: true, message: "This field is required!" },
            ])}
            {renderTextField("present_address", "Present Address", [
              { required: true, message: "This field is required!" },
            ])}

            {renderInput("pickup_commission", "Pickup Commision", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("delivery_commission", "Delivery Commision", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("odd_commission", "ODD Commision", [
              { required: true, message: "This field is required!" },
            ])}

            {renderInput("salary", "Salary", [
              { required: true, message: "This field is required!" },
            ])}
            {warehouse
              ? renderSelect(
                  "warehouse_id",
                  "Working Warehouse",
                  [{ required: true }],
                  "Select a option",
                  option,
                  "none"
                )
              : null}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Driver Details"
        subTitle="Driver Management"
        extra={[
          <>
            <Button
              size="small"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                setVisibleEdit(true);
              }}
            >
              Edit
            </Button>
            {driveredit}

            <Button
              size="small"
              type="primary"
              icon={<KeyOutlined />}
              onClick={() => handlePassVisible(data?.user_id)}
            >
              Password Setting
            </Button>
            {chnagepass}
          </>,
        ]}
      ></PageHeader>
      <br></br>
    </>
  );
  const driverdetails = (
    <>
      <Card >
        <Divider>Driver Details</Divider>
        <Card.Grid
          style={{
            width: "25%",
            textAlign: "center",
            backgroundColor: "#b91e1e",
            color: "white",
            maxHeight: 250,
            minHeight: 250,
          }}
        >
          Id: {data?.user_id}
          <br></br>
          Name: {data?.name}
          <br></br>
          Phone: {data?.mobile_number}
          <br></br>
          Email: {data?.email}
        </Card.Grid>
       
        <Card.Grid
          style={{
            width: "25%",
            textAlign: "center",
            backgroundColor: "#b91e1e",
            color: "white",
            maxHeight: 250,
            minHeight: 250,
          }}
        >
          {data?.authenticated ? (
            <span>Status: <strong>Active</strong></span>
          ) : (
            <span>Status: <strong>Inactive</strong></span>
          )}
          <br></br>
          Salary: {data?.salary}
          <br></br>
          Warehouse Name: {data?.warehouse?.name}
          <br></br>
          Present Address: {data?.permanent_address}
          <br></br>
          Palanty: {data?.penalty}
        </Card.Grid>
      </Card>
      <br></br>
      <p></p>

      <Card hoverable>
        <Divider style={{ color: "#b91e1e" }}>On Going Statistics</Divider>
        <Card.Grid style={gridStyle}>
          Processing Order : {data1?.processing}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Total Delivery : {data1?.delivered}
        </Card.Grid>
        <Card.Grid style={gridStyle}>Total Retun : {data1?.return}</Card.Grid>
        <Card.Grid style={gridStyle}>Total Hold : {data1?.hold}</Card.Grid>

        <Card.Grid style={gridStyle}>
          Collection : {data1?.collect_amount}
        </Card.Grid>
        <br></br>
      </Card>
      <br></br>
      <Card hoverable>
        <Divider style={{ color: "#b91e1e" }}>Total Statistics</Divider>
        <Card.Grid style={gridStyle}>
          Total delivery Income : {data2?.delivery_income}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Total Picked : {data2?.total_picked}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Total Pickup Income : {data2?.pickup_income}
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          Total Delivered : {data2?.total_delivered}
        </Card.Grid>

        <br></br>
      </Card>
      <br></br>

      {data?.logs ? (
        <>
          <Title>Logs </Title>
          <br />
          {data?.logs ? (
            <Timeline mode="left">
              {data?.logs.map((item, index) => (
                <Timeline.Item
                  label={moment
                    .utc(item?.created_at)
                    .format("YYYY-MM-DD hh:mm A")}
                  keys={index}
                >
                  {item?.message}
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Empty />
          )}
        </>
      ) : null}
      <br />
    </>
  );
  return (
    <Content>
      {pageheader}
      <br></br>
      {driverdetails}
    </Content>
  );
};

export default connect(null, {
  getDriverById,
  getOngoing,
  getTotalStac,
  updateDriverPass,
  getDriverEditData,
  getWarehouses,
  updateDriver,
})(DriverDetails);
