import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getPayableDriver,searchdrivers } from "../../../actions/account";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

const PaybaleDriver = ({ getPayableDriver }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getPayableDriver();
    setData(res);
    
  }, []);

  const handleVisible=async(id)=>{
    history.push(`/driver-details?driver=${id}`);
  }


  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });
  const columns = [
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      width: "20%",
      ...searchColumns("user_id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...searchColumns("name"),
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Payable",
      dataIndex: "account_balance",
      key: "account_balance",
      width: "20%",
      
    },
    {
      title: "Authenticated",
      dataIndex: "authenticated",
      key: "authenticated",
      width: "20%",
     
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
    },
  ];
  const createData = (
    user_id,
    name,
    mobile_number,
    account_balance,
    authenticated,
    action
  ) => {
    return {
      user_id,
      name,
      mobile_number,
      account_balance,
      authenticated,
      action,
    };
  };

  const rows = [
    data
      ? data?.map((payable) =>
          createData(
            payable?.user_id,
            payable?.name,
            payable?.mobile_number,
            <>{payable?.account_balance} TK</>,
            payable?.authenticated ? (
              <Tag style={{color:'#b91e1e'}}>Authenticated</Tag>
            ) : (
              <Tag style={{color:'#FF6347'}}>Unathenticated</Tag>
            ),

            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => {handleVisible( payable?.user_id)}}
            >
              View
            </Button>
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Payable List"
      subTitle="Finance Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </Content>
  );
};

export default connect(null, {
  getPayableDriver,
})(PaybaleDriver);
