import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
  GET_PRICING_MODEL,
} from "../reducers/type";

export const getOngoingPricingModel = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/area-pricing-management/ongoing-pricing-model"
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getPrice = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/area-pricing-management/all-pricing-model"
    );

    if (response.status === 200) {
      dispatch({ type: GET_PRICING_MODEL, payload: response.data });
      dispatch({ type: LOADED });
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const activePricingModel = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch(
      `/area-pricing-management/active-pricing-model`,
      {
        pricing_id: id,
      }
    );
    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Successfully Updated" });
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const deactivePricingModel = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch(
      `/area-pricing-management/deactive-all-pricing-model`
    );

    if (response.status === 201) {
      dispatch({ type: SUCCESS_MESSAGE, payload: "Successfully Deactivated" });
      dispatch({ type: LOADED });
      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const addPrice = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  const obj = {
    name: data.name,
    vat: data.vat,
    in_city: {
      delivery_charge: data.inCity_delivery_charge,
      return_charge: data.inCity_return_charge,
      cod: data.inCity_cod,
      base_weight: data.inCity_base_weight,
      max_weight: data.inCity_max_weight,
      ECPK: parseInt(data.inCity_ECPK, 10),
      ODD_delivery_charge: data.inCity_odd_delivery_charge,
      ODD_return_charge: data.inCity_odd_return_charge,
      ODD_cod: data.inCity_odd_cod,
      ODD_base_weight: data.inCity_odd_base_weight,
      ODD_max_weight: data.inCity_odd_max_weight,
      ODD_ECPK: parseInt(data.inCity_odd_ECPK, 10),
    },
    in_sub_city: {
      delivery_charge: data.subCity_delivery_charge,
      return_charge: data.subCity_return_charge,
      cod: data.subCity_cod,
      base_weight: data.subCity_base_weight,
      max_weight: data.subCity_max_weight,
      ECPK: parseInt(data.subCity_ECPK, 10),
    },
    outer_city: {
      delivery_charge: data.outerCity_delivery_charge,
      return_charge: data.outerCity_return_charge,
      cod: data.outerCity_cod,
      base_weight: data.outerCity_base_weight,
      max_weight: data.outerCity_max_weight,
      ECPK: parseInt(data.outerCity_ECPK, 10),
    },
    odd: data.odd,
    odd_time: {
      end: data.end_time.format('HH:mm'),
      start: data.start_time.format('HH:mm')
    },
    
  };

 
  try {
    
    const response = await api(localStorage.getItem("token")).post(
      "/area-pricing-management/create-pricing-model",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: response.data.message,
      });
      dispatch({ type: LOADED });
      history.push("/pricie-model");
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getOngoingPricingModelDetails = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    const response =  await api (localStorage.getItem("token")).get(`/area-pricing-management/get-pricing-model?pricing_id=${id}`);
    if(response.status === 200) {
      dispatch({type: LOADED})
      return response.data.model;
    }
  }catch(err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const getPricingModelForEdit = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
    const response =  await api (localStorage.getItem("token")).get(`/area-pricing-management/get-pricing-model?pricing_id=${id}`);
    if(response.status === 200) {
      dispatch({type: LOADED})
      dispatch({type: GET_PRICING_MODEL, payload: response.data.model})
      return response.data.model;
    }
  }catch(err){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}

export const editPrice = (data, id) => async (
  dispatch
) => {
  dispatch({ type: LOADED });
  const obj = {
      
      name: data.name,
      vat: data.vat,
      pricing_id : id,
      in_city: {
        delivery_charge: data.inCity_delivery_charge,
        return_charge: data.inCity_return_charge,
        cod: data.inCity_cod,
        base_weight: data.inCity_base_weight,
        max_weight: data.inCity_max_weight,
        ECPK: parseInt(data.inCity_ECPK,10),
        ODD_delivery_charge: data.inCity_odd_delivery_charge,
        ODD_return_charge: data.inCity_odd_return_charge,
        ODD_cod: data.inCity_odd_cod,
        ODD_base_weight: data.inCity_odd_base_weight,
        ODD_max_weight: data.inCity_odd_max_weight,
        ODD_ECPK: parseInt(data.inCity_odd_ECPK,10),
      },
      in_sub_city: {
          delivery_charge: data.subCity_delivery_charge,
          return_charge: data.subCity_return_charge,
          cod: data.subCity_cod,
          base_weight: data.subCity_base_weight,
          max_weight: data.subCity_max_weight,
          ECPK: parseInt(data.subCity_ECPK,10),
      },
      outer_city: {
          delivery_charge: data.outerCity_delivery_charge,
          return_charge: data.outerCity_return_charge,
          cod: data.outerCity_cod,
          base_weight: data.outerCity_base_weight,
          max_weight: data.outerCity_max_weight,
          ECPK: parseInt(data.outerCity_ECPK, 10),
      },
      odd:data.odd,
      
      odd_time: {
        end: data.end_time.format("HH:mm"),
        start: data.start_time.format("HH:mm")
      }
    }
  try {
   
    const response = await api(localStorage.getItem("token")).patch(
      "/area-pricing-management/update-pricing-model",
      { ...obj },
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      dispatch({ type: LOADED });
      history.push("/pricie-model");
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};