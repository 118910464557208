import {
  EyeOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,
} from "antd";

import { Content } from "antd/lib/layout/layout";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTotaltransactions,
  TransactionreportDownloader,
} from "../../../actions/account";
import history from "../../../history";
const { RangePicker } = DatePicker;
const TotalTransaction = ({
  getTotaltransactions,
  TransactionreportDownloader,
}) => {
  const [data, setData] = useState([]);
  const [ds, setDs] = useState('');
  const [de, setDe] = useState('');
  const [st, setTransaction] = useState([null]);

  const handleApiSearch = async () => {
    window.scrollTo(0, 0);
    const res = await getTotaltransactions(ds, de, st);

    setData(res);
  };

  const handleVisible = async (id) => {
    history.push(`/transaction-details?transaction_id=${id}`);
  };

  const handleApiDownload = async () => {
    const res = await TransactionreportDownloader(ds, de);

    setData(res);
  };
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const handleDate = (date) => {
    if (date) {
      let ds = new Date(date[0]._d);
      let de = new Date(date[1]._d);
      setDs(ds.toISOString());
      setDe(de.toISOString());
    } else {
      setDs(null);
      setDe(null);
      return;
    }
  };

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "25%",
      ...searchColumns("transaction_id"),
    },
    {
      title: "Transaction State",
      dataIndex: "transaction_state",
      key: "transaction_state",
      width: "20%",
      ...searchColumns("transaction_state"),
    },
    {
      title: "Transaction Flow",
      dataIndex: "transaction_flow",
      key: "transaction_flow",
      width: "15%",
    },
    {
      title: "Transaction Amount",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: "15%",
      ...searchColumns("transfer_amount"),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "18%",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "15%",
    },
  ];
  const createData = (
    transaction_id,
    transaction_state,
    transaction_flow,
    transfer_amount,
    created_at,
    action
  ) => {
    return {
      transaction_id,
      transaction_state,
      transaction_flow,
      transfer_amount,
      created_at,

      action,
    };
  };

  const rows = [
    data
      ? data?.transactions?.map((TRANSACTION, id) =>
          createData(
            TRANSACTION?.transaction_id,
            TRANSACTION?.transaction_state,
            TRANSACTION?.transaction_state === "Shop Payable" ? (
              <>{TRANSACTION?.transaction_flow?.bahon}</>
            ) : (
              <>{TRANSACTION?.transaction_flow?.bahon}</>
            ),

            TRANSACTION?.transfer_amount,
            moment.utc(TRANSACTION.created_at).format("YYYY-MM-DD hh:mm A"),
            <Button
              size="small"
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => handleVisible(TRANSACTION?.transaction_id)}
            >
              View
            </Button>
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
     {window.scrollTo(0, 0)}
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Transaction List"
        subTitle="Finance Management"
      ></PageHeader>
      <br></br>
    </>
  );
  const TABLE = (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Col xs={10} sm={24} md={10} lg={10} xl={10}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>
        <Col xs={8} sm={24} md={8} lg={8} xl={8}>
          <Input
            placeholder="Transaction ID"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setTransaction(e.target.value)}
          />
        </Col>
        <br></br>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Tooltip title="search" onClick={handleApiSearch}>
            <Button type="primary" shape="circle" icon={<SearchOutlined />} />
          </Tooltip>
        </Col>
        <br></br>
       
        {ds !== '' && de !== '' ?  <>
        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
          <Tooltip title="download" onClick={handleApiDownload}>
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
            />
          </Tooltip>
        </Col>
        <br />
      </>:(
          null
        ) }
      </Row>
      <br />
      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </>
  );
  return (
    <Content>
      {Pageheader}
      {TABLE}
    </Content>
  );
};

export default connect(null, {
  getTotaltransactions,
  TransactionreportDownloader,
})(TotalTransaction);
