import axios from "axios";
import history from "../history";
import api from "./apis/index";
import {
  AUTH_ERROR,
  AUTH_SUCCESS,
  ERROR_MESSAGE,
  GET_DRIVERS,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  GET_DRIVERS_ON_MAP,
  VALIDATION_ERROR,
} from "../reducers/type";

export const getdriverStatistic = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `driver-management/statistics`
    );
    dispatch({ type: LOADED });
    return response.data.statistics[0];
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getDrivers = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "driver-management/get-drivers"
    );
    dispatch({ type: LOADED });
    dispatch({ type: GET_DRIVERS, payload: response.data });
    return response.data;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};
export const getDriversMap = () => async (dispatch) => {
    
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).get(
    "driver-management/get-drivers");
  dispatch({ type: LOADED });
  dispatch({ type: GET_DRIVERS, payload: response.data });
} catch (err) {
  /* =============Start========== */
    if(err?.response?.status === 422){
      err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      })
    }
    if(err?.response?.status === 401 || err?.response?.status === 403 ){
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
        localStorage.clear();
        history.push('/login')
    }
    dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
    dispatch({type: LOADED});
  /* ===========End============*/
}
};

export const searchdrivers = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `finance-management/get-driver?driver=${id}`
    );
    dispatch({ type: LOADED });
    return response.data.driver;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const createDriver = (data) => async (dispatch) => {
     
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/driver-management/create-driver",
      { ...data }
    );

    if (response.status === 200) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Driver is created successfully",
      });
      history.push("/driver-list");
    }
  } catch (err) {
   
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    
  }
}

export const updateDriverAuth = (user_id,authenticated,remark) => async (dispatch) => {
    
  dispatch({ type: LOADING });
  try {
    const obj =
    {
      "user_id": user_id,
      "authenticated" : authenticated,
      "remark" : ''
    }
    const response = await api(localStorage.getItem("token")).patch("driver-management/auth-driver", {...obj});
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Driver auth status updated successfully",
      });
      return response.status
    }
    dispatch({ type: LOADED });
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};

export const getDriverById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
      const response = await api(localStorage.getItem("token")).get(
          `/driver-management/get-driver?user_id=${id}`
      )
        if(response.status === 200 ){
          dispatch({ type: LOADED });
          return response.data
        }
      dispatch({ type: LOADED });

  }catch(err){
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
  
}

export const getOngoing = (user_id) => async (dispatch) => {
    
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).get(
    `driver-management/driver-ongoing-statistics?user_id=${user_id}`);
  dispatch({ type: LOADED });
  return response.data.onGoingStatistics;
} catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};
// ============Get TotalStac===
export const getTotalStac = (user_id) => async (dispatch) => {
    
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).get(
    `driver-management/driver-total-statistics?user_id=${user_id}`);
  dispatch({ type: LOADED });
  return response.data.totalStatistics[0];
} catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
};

// ============Driver Pass chnage===
export const updateDriverPass = (data,user_id) => async (dispatch) => {
    
  dispatch({ type: LOADING });
  try {
    const obj =
    {
      "user_id": user_id,
      "password" : data.password,
      "remark" : data.remark
    }
    const response = await api(localStorage.getItem("token")).patch("driver-management/update-driver-password", {...obj});
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Driver password updated successfully",
      });
      return response.status;
    }
    dispatch({ type: LOADED });
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};

export const getDriverEditData = (id) => async (dispatch) => {
  
  dispatch({ type: LOADING });
try {
  const response = await api(localStorage.getItem("token")).get(
    `driver-management/get-edit-driver?user_id=${id}`);
    if(response.status == 200){
      localStorage.setItem('driverEdit', JSON.stringify(response.data.driver));
      dispatch({ type: LOADED });
      return response.data.driver;
      
    }
  dispatch({ type: LOADED });
} catch (err) {
  /* =============Start========== */
    if(err?.response?.status === 422){
      err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      })
    }
    if(err?.response?.status === 401 || err?.response?.status === 403 ){
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
        localStorage.clear();
        history.push('/login')
    }
    dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
    dispatch({type: LOADED});
  /* ===========End============*/
}
}
export const updateDriver = (data) => async (dispatch) => {
    
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).patch("driver-management/update-driver", {...data});
    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Driver is updated successfully",
      });
      history.push("/driver-list");
    }
    dispatch({ type: LOADED });
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        err.response.data.errors.map(item => {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
      dispatch({type: LOADED});
    /* ===========End============*/
  }
};
export const getDriversOnMap = ( values) => async (dispatch) => {
  console.log(values)
  dispatch({ type: LOADING });

  const id=values?.driver
try {
  const response = await api(localStorage.getItem("token")).get(
    `driver-management/get-driver-location?user_id=${id }&date=${values.Days ? values.Days : 'td'}`);

    response.data.locationData = response.data.locationData.map(item => JSON.parse(item) )
  dispatch({ type: LOADED });
  dispatch({ type: GET_DRIVERS_ON_MAP, payload: ( response.data) });
  
 
   return  response.data
   
 
} catch (err) {
  /* =============Start========== */
    if(err?.response?.status === 422){
      err.response.data.errors.map(item => {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
      })
    }
    if(err?.response?.status === 401 || err?.response?.status === 403 ){
        dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
        localStorage.clear();
        history.push('/login')
    }
    dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/notFound') });
    dispatch({type: LOADED});
  /* ===========End============*/
}
};