import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Tag,
  Card,
  Col,
  Row,
  Divider,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  warehouseMainStatistics,
  getAllWarehousesManager,
  getAllWarehousesTotal,
} from "../../../actions/warehouse";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const WarehouseStatistic = ({
  warehouseMainStatistics,
  getAllWarehousesManager,
  getAllWarehousesTotal,
}) => {
  const [lastmonthcollection, setLastMonthCollection] = useState([]);

  const [lastyearcollection, setLastYearCollection] = useState([]);
  const [dataM, setDataM] = useState([]);
  const [dataT, setDataT] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const warehouse_statistic = await warehouseMainStatistics();
    const Manager = await getAllWarehousesManager();
    setDataM(Manager);
    const warehouseTotal = await getAllWarehousesTotal();
    setDataT(warehouseTotal);

    let lintChartDataLMC = [];
    let lintChartDataLYC = [];

    for (
      let i = 0;
      i < warehouse_statistic?.statistics[0]?.lastMonthCollection?.length;
      i++
    ) {
      lintChartDataLMC.push({
        name: "Total Tk",
        count:
          warehouse_statistic?.statistics[0]?.lastMonthCollection[i]?.total,
        date: warehouse_statistic?.statistics[0]?.lastMonthCollection[i]
          ?.warehouse?.name,
      });
    }
    setLastMonthCollection(lintChartDataLMC);

    for (
      let i = 0;
      i < warehouse_statistic?.statistics[0]?.lastYearCollection?.length;
      i++
    ) {
      lintChartDataLYC?.push({
        name: "Total Tk",
        count: warehouse_statistic?.statistics[0]?.lastYearCollection[i]?.total,
        date: warehouse_statistic?.statistics[0]?.lastYearCollection[i]
          ?.warehouse?.name,
      });
    }

    setLastYearCollection(lintChartDataLYC);
  }, []);



  const lMC = {
    data: lastmonthcollection,
    xField: "date",
    yField: "count",

    seriesField: "name",
    color: ({ name }) => {
      return name === "name" ? "#b91e1e" : "#b91e1e";
    },

    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: "top",
    },

    smooth: false,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  const Main_chart_LMC = (
    <>
      <Divider>Last Month Collection(Tk)</Divider>
      <Line {...lMC} />
    </>
  );

  const LYC = {
    data: lastyearcollection,
    xField: "date",
    yField: "count",

    seriesField: "name",
    color: ({ name }) => {
      return name === "name" ? "#231a52" : "#231a52";
    },

    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: "top",
    },

    smooth: false,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  const Main_chart_LYC = (
    <>
      <Divider>Last Year Collection(Tk)</Divider>
      <Line {...LYC} />
    </>
  );


  return (
    <Content>
      
     
      <Row gutter={16}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          style={{ textAlign: "center" }}
        >
          <Card
            title=""
            style={{
              textAlign: "center",
              backgroundColor: "",
              color: "white",
            }}
            bordered={false}
          >
            {Main_chart_LMC}
            <br></br>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          style={{ textAlign: "center" }}
        >
          <Card
            title=""
            style={{
              textAlign: "center",
              backgroundColor: "",
              color: "white",
            }}
            bordered={false}
          >
            {Main_chart_LYC}
            <br></br>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default connect(null, {
  warehouseMainStatistics,
  getAllWarehousesManager,
  getAllWarehousesTotal,
})(WarehouseStatistic);
