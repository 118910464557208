import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import { getAllWarehouses } from "../../../actions/warehouse";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Card,
  Row,
  Col,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";

const WareHouse = ({ getAllWarehouses }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getAllWarehouses();
    setData(res);
    
  }, []);
  const handleVisible = async ( warehouse_id) => {
   
    history.push(`/warehouse-details?warehouse_id=${warehouse_id}`);
  };

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse List"
      subTitle="Finance Management"
    ></PageHeader>
  );
  const CARD = (
    <>
    <Row gutter={16} justify="center">
      {data
        ? data.map((item, id) => (
            <>
              
                <Col xs={6} lg={8} sm={24} md={12}>&nbsp;
              
                  <Card hoverable style={{ width: "250px",backgroundColor:'#b91e1e',color:'white' }}  onClick={() => { handleVisible( item?.warehouse_id);}}>
                     <strong> ID: {item?.warehouse_id}</strong><br></br>
                     <strong> Name: {item?.name}</strong><br></br>
                     <strong> Balance: {item?.account_balance}</strong><br></br>
                     
                  </Card>
                 
                </Col>
               
             
            </>
          ))
        : ""}
         </Row>
    </>
  );

  return (
    <Content>
      {Pageheader}
      {CARD}
    </Content>
  );
};

export default connect(null, {
  getAllWarehouses,
})(WareHouse);
