import React, { useEffect } from "react";
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { getAreas } from "../../../actions/area";
import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import {
  CheckCircleOutlined,
  CheckSquareFilled,
  EditOutlined,
  EyeOutlined,
  KeyOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import {
  getWarehouseEditData,
  getAlWarehouseDetails,
  warehouseStatistics,
  updateWarehouse,
} from "../../../actions/warehouse";

import {
  renderDate,
  renderInput,
  renderPasswordField,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import { Title, Wrapper } from "../../../Style";
import moment from "moment";
import history from "../../../history";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

const banks = [
  { value: "AB Bank LTD", label: "AB Bank LTD" },
  { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
  { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
  { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
  {
    value: "BDBL (Bangladesh Development Bank LTD)",
    label: "BDBL (Bangladesh Development Bank LTD)",
  },
  { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
  {
    value: "Bangladesh Commerce Bank LTD",
    label: "Bangladesh Commerce Bank LTD",
  },
  { value: "Bank Al-Falah", label: "Bank Al-Falah" },
  { value: "Bank Asia LTD", label: "Bank Asia LTD" },
  { value: "Citibank NA", label: "Citibank NA" },
  { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
  { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
  { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
  { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
  {
    value: "Export Import Bank of Bangladesh LTD",
    label: "Export Import Bank of Bangladesh LTD",
  },
  {
    value: "First Security Islami Bank LTD",
    label: "First Security Islami Bank LTD",
  },
  { value: "HSBC", label: "HSBC" },
  { value: "Habib Bank LTD", label: "Habib Bank LTD" },
  { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
  { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
  {
    value: "Islami Bank Bangladesh LTD",
    label: "Islami Bank Bangladesh LTD",
  },
  { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
  { value: "Janata Bank LTD", label: "Janata Bank LTD" },
  { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
  { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
  { value: "Midland Bank LTD", label: "Midland Bank LTD" },
  { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
  { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
  { value: "NCC Bank LTD", label: "NCC Bank LTD" },
  { value: "NRB Bank LTD", label: "NRB Bank LTD" },
  { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
  { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
  { value: "National Bank LTD", label: "National Bank LTD" },
  { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
  { value: "One Bank LTD", label: "One Bank LTD" },
  { value: "Prime Bank LTD", label: "Prime Bank LTD" },
  { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
  { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
  { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
  { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
  { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
  { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
  {
    value: "South Bangla Agriculture and Commerce Bank LTD",
    label: "South Bangla Agriculture and Commerce Bank LTD",
  },
  { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
  { value: "Standard Bank LTD", label: "Standard Bank LTD" },
  { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
  { value: "State Bank of India", label: "State Bank of India" },
  { value: "The City Bank LTD", label: "The City Bank LTD" },
  { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
  { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
  { value: "Trust Bank LTD", label: "Trust Bank LTD" },
  { value: "Union Bank LTD", label: "Union Bank LTD" },
  {
    value: "United Commercial Bank LTD",
    label: "United Commercial Bank LTD",
  },
  { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
  { value: "Woori Bank", label: "Woori Bank" },
];
const account_type = [
  { value: "Saving", label: "Saving" },
  { value: "Current", label: "Current" },
];
const payment_method = [
  { value: "Mobile Banking", label: "Mobile Banking" },
  { value: "Bank Account", label: "Bank Account" },
  { value: "Cash", label: "Cash" },
];
const mobile_type = [
  { value: "bKash", label: "bKash" },
  { value: "Rocket", label: "Rocket" },
  { value: "Nagad", label: "Nagad" },
];
const bank_payment_option = [
  { value: "Check Voucher", label: "Check Voucher" },
  { value: "Voucher", label: "Voucher" },
];

const active = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const authenticated = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const merchant = [
  { value: "Company", label: "Company" },
  { value: "Individual", label: "Individual" },
];
const doc = [
  { value: "NID", label: "NID" },
  { value: "Passport", label: "Passport" },
  { value: "Driving_Licence", label: "Driving Licence" },
  { value: "None", label: "None" },
];
const WarehouseDetails = ({
  location,
  getWarehouseEditData,
  getAlWarehouseDetails,
  warehouseStatistics,
  getAreas,
  updateWarehouse,
}) => {
  const [data, setData] = useState([]);
  const [passVisible, setPassVisible] = useState(false);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [placement, setPlacement] = useState("left");
  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setSubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [coverageArea, setCoverageArea] = useState(null);
  const [ID, setId] = useState("");

  const [form] = Form.useForm();

  const warehouse_id = new URLSearchParams(location.search).get("warehouse_id");
  async function fetchData() {
    const res = await getAlWarehouseDetails(warehouse_id);
    const res1 = await warehouseStatistics(warehouse_id);
    const res2 = await getWarehouseEditData(warehouse_id);

    const area = await getAreas();
    setCoverageArea(area);
    const splitAreaCode = res2?.area_code?.split("-");

    setCity(splitAreaCode[0]);
    setSubCity(splitAreaCode[1]);
    setThana(splitAreaCode[2]);
    setArea(splitAreaCode[3]);

    setSubCityArr(area?.city[splitAreaCode[0] - 1]?.sub_city);

    setThanaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana
    );

    setAreaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana[
        splitAreaCode[2] - 1
      ].area
    );

    setData(res);
    setData1(res1);
    setData2(res2);
    form.setFieldsValue({
      warehouse_id: res2.warehouse_id,
      warehouse_name: res2.name,
      mobile_number: res2.mobile_number,
      warehouse_address: res2.address,

      city: splitAreaCode[0],
      subCity: splitAreaCode[1],
      thana: splitAreaCode[2],
      area: splitAreaCode[3],
      area_code: city + "-" + subCity + "-" + thana + "-" + area,
    });

    // console.log('1st',res);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);
    // console.log(id);
  };

  const onFinish = async (values) => {
    const code = await updateWarehouse(values, warehouse_id);
   
    if (code === 201) {
      setPassVisible(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setPassVisible(false);
  };
  const handleOkEdit = () => {
    setPassVisible(false);
  };
  const handleCancel = () => {
    setPassVisible(false);
  };
  const handleCancelEdit = () => {
    setPassVisible(false);
  };
  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setSubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: '',
      thana: '',
      area: '',
     
  });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
    
      thana: '',
      area: '',
     
  });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      
      area: '',
     
  });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);

  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) => {
          return item?.active ? (
            <Option key={`${id}`} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) => {
          return item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  const warehouseEdit = (
    <>
      <Modal
        title="Warehouse Edit"
        centered
        visible={passVisible}
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        height={500}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Divider>Warehouse Information</Divider>
            {renderInput("warehouse_id", " ID", [
              { required: true, message: "This field is required" },
            ])}
            {renderInput("warehouse_name", " Name", [
              { required: true, message: "This field is required" },
            ])}
            {renderInput("mobile_number", " Phone No", [
              { required: true, message: "This field is required" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
            {renderTextField("warehouse_address", " Address", [
              { required: true, message: "This field is required" },
            ])}
            <Divider>Select Area</Divider>

            {/* ================ City =============== */}

            <Form.Item
              name="city"
              label={"Select City"}
              rules={[
                {
                  required: true,
                  message: "City  is required",
                },
              ]}
            >
              <Select showSearch
                placeholder={"Select one city"}
                onChange={(e) => city_(e)}
                value={city}
              >
                <Option value="-1">Select City</Option>
                {c_o}
              </Select>
            </Form.Item>
            {/* ================ Sub City ============ */}
            {subCityArr ? (
              <Form.Item
                name="subCity"
                label={"Select Subcity"}
                rules={[
                  {
                    required: true,
                    message: "Subcity is required",
                  },
                ]}
              >
                <Select showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => sub_city(e)}
                  value={subCity}
                >
                  {/* {options} */}
                  {s_o}
                </Select>
              </Form.Item>
            ) : null}

            {/* ============== Thana ============== */}
            {thanaArr ? (
              <Form.Item
                name="thana"
                label={"Select one thana"}
                rules={[
                  {
                    required: true,
                    message: "Thana is required",
                  },
                ]}
              >
                <Select showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => thana_(e)}
                  value={thana}
                >
                  {/* {options} */}
                  {t_o}
                </Select>
              </Form.Item>
            ) : null}
            {/* ============== Area ============= */}
            {areaArr ? (
              <Form.Item
                name="area"
                label={"Select one area"}
                rules={[
                  {
                    required: true,
                    message: "Area is required",
                  },
                ]}
              >
                <Select showSearch
                  placeholder={"Select one area"}
                  onChange={(e) => area_(e)}
                  value={area}
                >
                  {/* {options} */}
                  {a_o}
                </Select>
              </Form.Item>
            ) : null}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Warehouse Details"
        subTitle="Warehouse Management"
        extra={
          <>
            <Button
              size="small"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handlePassVisible(data.user_id)}
            >
              Edit
            </Button>
            {warehouseEdit}
          </>
        }
      ></PageHeader>
      <br></br>
    </>
  );
  const WarehouseDetails = (
    <>
      <Card hoverable>
        <div style={{ display: "inline-block" }}>
          <h1 style={{ color: "#b91e1e" }}>Id: {data?.warehouse_id}</h1>
          <h1 style={{ color: "#b91e1e" }}>Name: {data?.name}</h1>
          <h1 style={{ color: "#b91e1e" }}>Phone: {data?.mobile_number}</h1>
          {data?.active ? (
            <h1 style={{ color: "#b91e1e" }}>Active</h1>
          ) : (
            <h1 style={{ color: "red" }}>Deativate</h1>
          )}
          <h1 style={{ color: "red" }}>Balance: {data?.account_balance}</h1>
          <h1 style={{ color: "#b91e1e" }}>Address: {data?.address}</h1>
        </div>
        &nbsp;
        <div style={{ display: "inline-block", float: "right" }}>
          <h1 style={{ color: "#b91e1e" }}>
            Total Collection : {data1?.total_collection} TK
          </h1>
          <h1 style={{ color: "#b91e1e" }}>
            Total Delivered : {data1?.total_delivered}{" "}
          </h1>
          <h1 style={{ color: "#b91e1e" }}>
            Total Manager : {data1?.total_manager}
          </h1>
          <h1 style={{ color: "#b91e1e" }}>
            Total Picked : {data1?.total_picked}
          </h1>
        </div>
      </Card>
      <br></br>

      {data?.logs ? (
        <>
          <Title>Logs </Title>
          <br />
          {data?.logs ? (
            <Timeline mode="left">
              {data?.logs.map((item, index) => (
                <Timeline.Item
                  label={moment
                    .utc(item?.created_at)
                    .format("YYYY-MM-DD hh:mm A")}
                  keys={index}
                >
                  {item?.message}
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Empty />
          )}
        </>
      ) : null}
      <br />
    </>
  );

  return (
    <Content>
      {pageheader}
      <br></br>
      {WarehouseDetails}
    </Content>
  );
};

export default connect(null, {
  getWarehouseEditData,
  getAlWarehouseDetails,
  getAreas,
  warehouseStatistics,
  updateWarehouse,
})(WarehouseDetails);
