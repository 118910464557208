import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  PageHeader,
  Table,
  Col,
  Row,
  Tooltip,
  Select,
  DatePicker,
  Pagination,
  Descriptions,
  Divider,
  Card,
  Tag,
  Form,
  Modal,
} from "antd";

import { Content } from "antd/lib/layout/layout";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { searchdrivers } from "../../../actions/Driver";
import { payToDriver } from "../../../actions/account";
import { renderInput, renderSelect } from "../../../components/form";
import SelectBox from "../../../components/select";
const { RangePicker } = DatePicker;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};
const banks = [
  { value: "AB Bank LTD", label: "AB Bank LTD" },
  { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
  { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
  { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
  {
    value: "BDBL (Bangladesh Development Bank LTD)",
    label: "BDBL (Bangladesh Development Bank LTD)",
  },
  { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
  {
    value: "Bangladesh Commerce Bank LTD",
    label: "Bangladesh Commerce Bank LTD",
  },
  { value: "Bank Al-Falah", label: "Bank Al-Falah" },
  { value: "Bank Asia LTD", label: "Bank Asia LTD" },
  { value: "Citibank NA", label: "Citibank NA" },
  { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
  { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
  { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
  { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
  {
    value: "Export Import Bank of Bangladesh LTD",
    label: "Export Import Bank of Bangladesh LTD",
  },
  {
    value: "First Security Islami Bank LTD",
    label: "First Security Islami Bank LTD",
  },
  { value: "HSBC", label: "HSBC" },
  { value: "Habib Bank LTD", label: "Habib Bank LTD" },
  { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
  { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
  {
    value: "Islami Bank Bangladesh LTD",
    label: "Islami Bank Bangladesh LTD",
  },
  { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
  { value: "Janata Bank LTD", label: "Janata Bank LTD" },
  { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
  { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
  { value: "Midland Bank LTD", label: "Midland Bank LTD" },
  { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
  { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
  { value: "NCC Bank LTD", label: "NCC Bank LTD" },
  { value: "NRB Bank LTD", label: "NRB Bank LTD" },
  { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
  { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
  { value: "National Bank LTD", label: "National Bank LTD" },
  { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
  { value: "One Bank LTD", label: "One Bank LTD" },
  { value: "Prime Bank LTD", label: "Prime Bank LTD" },
  { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
  { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
  { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
  { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
  { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
  { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
  {
    value: "South Bangla Agriculture and Commerce Bank LTD",
    label: "South Bangla Agriculture and Commerce Bank LTD",
  },
  { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
  { value: "Standard Bank LTD", label: "Standard Bank LTD" },
  { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
  { value: "State Bank of India", label: "State Bank of India" },
  { value: "The City Bank LTD", label: "The City Bank LTD" },
  { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
  { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
  { value: "Trust Bank LTD", label: "Trust Bank LTD" },
  { value: "Union Bank LTD", label: "Union Bank LTD" },
  {
    value: "United Commercial Bank LTD",
    label: "United Commercial Bank LTD",
  },
  { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
  { value: "Woori Bank", label: "Woori Bank" },
];
const account_type = [
  { value: "Saving", label: "Saving" },
  { value: "Current", label: "Current" },
];
const payment_method = [
  { value: "Mobile Banking", label: "Mobile Banking" },
  { value: "Bank Account", label: "Bank Account" },
  { value: "Cash", label: "Cash" },
];
const mobile_type = [
  { value: "bKash", label: "bKash" },
  { value: "Rocket", label: "Rocket" },
  { value: "Nagad", label: "Nagad" },
];
const bank_payment_option = [
  { value: "Check Voucher", label: "Check Voucher" },
  { value: "Voucher", label: "Voucher" },
];

const active = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const authenticated = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const merchant = [
  { value: "Company", label: "Company" },
  { value: "Individual", label: "Individual" },
];
const doc = [
  { value: "NID", label: "NID" },
  { value: "Passport", label: "Passport" },
  { value: "Driving_Licence", label: "Driving Licence" },
  { value: "None", label: "None" },
];
const SearchDriver = ({ searchdrivers, payToDriver, location }) => {
  const [data, setData] = useState([]);
  const [drivers, setDriver] = useState(false);
  const [Paymoney, setPayMoney] = useState(false);
  const [form] = Form.useForm();
  const [pt, setPaymentType] = useState("");
  const [bpo, setBPO] = useState("");
  const [docimg, setDocImg] = useState(false);
  const [ID, setID] = useState("");
  const user_id = new URLSearchParams(location.search).get("user_id");
  useEffect(async () => {
    window.scrollTo(0, 0);

    if (user_id) {
      const res = await searchdrivers(user_id);
     
      form.setFieldsValue({
        amount: res?.account_balance,
      });
      setDriver(res);
      setID(res.user_id);
    }
  }, []);

  const handlemerchantSearch = async (e) => {
    window.scrollTo(0, 0);
    let value = e.target.value;
    if (value.length === 7) {
      const res = await searchdrivers(value);
      setData(value);
      setDriver(res);
    }
    if (value.length < 7) {
      const res = await searchdrivers(value);
      setData(value);
      setDriver(res);
    }
    if (value.length > 7) {
      const res = await searchdrivers(value);
      setData(value);
      setDriver(res);
    }
  };
  const onFinish = async (values) => {
    const code = await payToDriver(values, drivers?.user_id, docimg);

    if (code === 201) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Driver Details"
      subTitle="Finance Management"
      extra={[]}
    ></PageHeader>
  );

  const SMS = (
    <>
      <Descriptions
        title="Search Driver"
        justify="center"
        labelStyle={{
          marginLeft: "150px",
        }}
      >
        <Descriptions.Item
          bordered={true}
          label="Driver  ID"
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={4}
        >
          <Input
            variant="filled"
            name="driver"
            label="Driver Id "
            placeholder="D220001"
            style={{ width: "50%" }}
            onChange={(e) => handlemerchantSearch(e)}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
  const FROM = (
    <Modal
      title={`Collection amount`}
      centered
      visible={Paymoney}
      width={600}
      onCancel={() => setPayMoney(false)}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Divider style={{ color: "#b91e1e" }}>
          Collecting From {data?.name}
        </Divider>
        {/* {renderDate("date", "Collection Date", [
            { required: true, message: "Field is required" },
          ])} */}
        <Form.Item
          label="Collection Date"
          name="date"
          rules={[{ required: true, message: "Field type is required" }]}
        >
          <DatePicker style={{ width: "100%" }} size="large" name="date" />
        </Form.Item>
        {renderInput("amount", "Amount to Collect", [
          { required: true, message: "field is required" },
        ])}
        {renderInput("note", "Note", [
          { required: true, message: "Field is required" },
        ])}
        <SelectBox
          name="payment_method"
          label="Payment Method"
          rules={[{ required: true, message: "payment type is required" }]}
          options={payment_method}
          placeholder="Select One"
          onChange={(e) => {
            setPaymentType(e);
          }}
        />
        {pt === "Mobile Banking" ? (
          <>
            {renderSelect(
              "mobile_bank",
              "Mobile Banking Name*",
              [{ required: true }],
              "Select One",
              mobile_type,
              "none"
            )}
            {renderInput("mobile_banking_account", "Mobile Number ", [
              { required: true, message: "Field is required" },
            ])}
            {renderInput("tx_id", "Transaction ID", [
              { required: true, message: "Field is required" },
            ])}

            <br></br>
          </>
        ) : null}
        {pt === "Bank Account" ? (
          <>
            <SelectBox
              name="bank_payment_option"
              label="Bank payment option"
              rules={[{ required: true, message: "Field is required" }]}
              options={bank_payment_option}
              placeholder="Select One"
              onChange={(e) => {
                setBPO(e);
              }}
            />

            {bpo === "Check Voucher" ? (
              <>
                {renderSelect(
                  "bank_name",
                  "Bank Name*",
                  [{ required: true }],
                  "Select One",
                  banks,
                  "none"
                )}

                {renderInput("branch_name", "Brance Name", [
                  { required: true, message: "Field is required" },
                ])}
                {renderInput("check_holder", "Check holder", [
                  { required: true, message: "field is required" },
                ])}
                {renderInput("issue_name", "Issuer Name ", [
                  { required: true, message: "field is required" },
                ])}
                {renderInput("check_number", "Check Number", [
                  { required: true, message: "field is required" },
                ])}
              </>
            ) : null}
            {bpo === "Voucher" ? (
              <>
                {renderSelect(
                  "bank_name",
                  "Bank Name*",
                  [{ required: true }],
                  "Select One",
                  banks,
                  "none"
                )}

                {renderInput("branch_name", "Brance Name", [
                  { required: true, message: "Field is required" },
                ])}
              </>
            ) : null}
          </>
        ) : null}
        <Form.Item label="Image of Documnet">
          <input
            type="file"
            id="avatar"
            name="doc_img"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setDocImg(e.target.files[0]);
            }}
          ></input>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const MD = (
    <>
      <>
        <Divider>Driver Details</Divider>
        <Row gutter={16} justify="center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Name"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.name}</p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Mobile"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.mobile_number}</p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Balance"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.account_balance} TK</p>
              <Button
                onClick={() => {
                  setPayMoney(true);
                }}
              >
                PAY
              </Button>
              <br></br>
              {FROM}
            </Card>
            <br></br>
          </Col>
          <br></br>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Penalty"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.penalty} TK </p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Status"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>
                {drivers?.account_balance ? (
                  <Tag color="pink">Active</Tag>
                ) : (
                  <Tag color="red">InActive</Tag>
                )}{" "}
              </p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={4}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Salary"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.salary} TK </p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Present Address"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,
              }}
              bordered={false}
            >
              <p>{drivers?.present_address} </p>
            </Card>
            <br></br>
          </Col>
          <br></br>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            style={{ textAlign: "center" }}
          >
            <Card
              title="Permanent Address"
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
                maxHeight:180,minHeight:180,              }}
              bordered={false}
            >
              <p>{drivers?.permanent_address} </p>
            </Card>
            <br></br>
          </Col>
          <br></br>
        </Row>
      </>
    </>
  );
  return (
    <Content>
      {Pageheader}
      {SMS}

      {data.length > 7 ? (
        <h1 style={{ textAlign: "center" }}>Invalid Driver Id</h1>
      ) : data.length === 7 ? (
        <>{MD}</>
      ) : data.length < 7 && data.length > 1 ? (
        <h1 style={{ textAlign: "center" }}>Insert Ex(D220002) Driver Id </h1>
      ) : null}
    </Content>
  );
};

export default connect(null, { searchdrivers, payToDriver })(SearchDriver);
