
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TopMerchantsNumber } from "../../../actions/merchant";
import { Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';
import { Column, Line } from "@ant-design/plots";


const TopMerchantDeshboard = ({
 
TopMerchantsNumber
}) => {
 
  const [data3,setData3]=useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const TMerchant = await TopMerchantsNumber();
    let lintChartData = []

    for (let i=0; i < TMerchant?.data?.length ; i++ ){

      lintChartData.push({
        
        count: TMerchant?.data[i]?.total_order,
        name: TMerchant?.data[i]?.merchant,
      })

    }
    console.log(lintChartData)

    setData3(lintChartData);



  
  }, []);

  

  
  const config2 = {
    data : data3,
    xField: 'name',
    yField: 'count',
    legend: false,
    label: {
      formatter: (datum) => {
        return `${(datum['$$percentage$$'] * 100).toFixed(2)}%`;
      },
    },
    conversionTag: {
      formatter: (datum) => {
        return `${((datum[FUNNEL_CONVERSATION_FIELD][1] / datum[FUNNEL_CONVERSATION_FIELD][0]) * 100).toFixed(2)}%`;
      },
    }, // 关闭 conversionTag 转化率 展示
    // conversionTag: false,
  };
  const Main_chart=(
    <>
    
    <Funnel {...config2} /><br></br> 
    </>
  )
 

  return (
    <Content>
  

      {Main_chart}

    </Content>
  );
};

export default connect(null, {
  
TopMerchantsNumber
})(TopMerchantDeshboard);
