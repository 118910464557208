import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../../history";
import {
  getWarehouses,
  updateWarehouseStatus,
  
} from "../../../actions/warehouse";
import {
  Table,
  Input,
  Button,
  PageHeader,
  Popconfirm,
  Breadcrumb,
  Tag,
  Row,
  Col,
  Card,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";

const WarehouseList = ({ getWarehouses, updateWarehouseStatus }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getWarehouses();
    setData(res);
  }, []);
  const handleVisible = async (id) => {
    history.push(`/get-warehouse-details?warehouse_id=${id}`);
  };
  const handleActive = async (id, status, remarks) => {
    const res = await updateWarehouseStatus(id, status, remarks);
    if (res !== undefined) {
      setData(res);
    }
  };
  const totalwarehouse = (
    <Row gutter={16}>
      {data?.map((item, id) => (
        <>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={8}
            xl={6}
            style={{ textAlign: "center" }}
          >
            <Card
              title={item?.name}
              style={{
                textAlign: "center",
                backgroundColor: "#b91e1e",
                color: "white",
              }}
              bordered={false}
            >
              {item?.warehouse_id}
              <br></br>
              {item?.account_balance} TK<br></br>
              {item?.active ? (
                <strong>Active</strong>
              ) : (
                <strong>Inactive</strong>
              )}
              <br></br>
              {moment.utc(item?.created_at).format("YYYY-MM-DD hh:mm A")}
              <br></br>
              <br></br>
              <Button
                type="primary"
                onClick={() => {
                  handleVisible(item?.warehouse_id);
                }}
              >
                {" "}
                view
              </Button>
              &nbsp;&nbsp;{" "}
              {item?.active ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    handleActive(item.warehouse_id, false, "");
                  }}
                >
                  {" "}
                  Deactivate
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    handleActive(item.warehouse_id, true, "");
                  }}
                >
                  {" "}
                  Activate
                </Button>
              )}
            </Card>
            <br></br>
          </Col>
          <br></br>
        </>
      ))}
    </Row>
  );

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse Details"
      subTitle="Warehouse Management"
    ></PageHeader>
  );

  return (
    <Content>
      {Pageheader}
      <br></br>
      {totalwarehouse}
    </Content>
  );
};

export default connect(null, {
  getWarehouses,
  updateWarehouseStatus,
})(WarehouseList);
