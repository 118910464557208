import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Table, Tag, Card, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ParcelStatistics } from "../../../actions/parcel";
import { Column, Line, Pie, getCanvasPattern } from "@ant-design/plots";

const ParcelDashboard = ({ ParcelStatistics }) => {
  const [data, setData] = useState([]);
  const [lastmonthStatistic, setLastMonthStatistic] = useState([]);
  const [totalStatistic, setTotalStatistic] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0)
    const parcel_statistic = await ParcelStatistics();

    const piechartData = [];

    if(parcel_statistic?.statistic[0]?.totalOrderStatus?.length !== 0){

      piechartData.push(
        {
          type: "Return",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.return,
        },
        {
          type: "Delivered",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.delivered,
        },
        {
          type: "Cancel",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.cancel,
        },
        {
          type: "Pending",
          value: parcel_statistic?.statistic[0]?.totalOrderStatus[0]?.pending,
        }
      );

    }
    setData(piechartData);

    setLastMonthStatistic(parcel_statistic?.statistic[0]?.lastMonthStatus[0]);
    setTotalStatistic(parcel_statistic?.statistic[0]?.totalOrderStatus[0]);

    let lintChartData = [];

    for (
      let i = 0;
      i < parcel_statistic?.statistic[0]?.lastYearReport?.length;
      i++
    ) {
      lintChartData.push({
        name: "parcel",
        count: parcel_statistic?.statistic[0]?.lastYearReport[i].order,
        date: parcel_statistic?.statistic[0]?.lastYearReport[i].month,
      });
    }

    setData3(lintChartData);
  }, []);

  const Pageheader = (
    <PageHeader
    
      ghost={false}
      onBack={() => window.history.back()}
      title="Parcel List"
      subTitle="Parcel Management"
    ></PageHeader>
  );

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "outer",
    },
    // 自定义状态样式
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    // 添加 element 选中和激活交互
    interactions: [
      {
        type: "element-single-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const Total_statistic = <Pie {...config} />;

  const config2 = {
    data: data3,
    xField: "date",
    yField: "count",

    seriesField: "name",
    color: ({ name }) => {
      return name === "name" ? "#30BF78" : "#30BF78";
    },

    yAxis: {
      label: {
        formatter: (v) => `${v} `,
      },
    },
    legend: {
      position: "top",
    },

    smooth: false,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  const Main_chart = <Line {...config2} />;

  const gridStyle = {
    width: "25%",
    textAlign: "center",
  };

  const AllOrderstatistic = (
    <>
      <Card title="Total Order Statistic">
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Order :{totalStatistic?.total_order}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Delivered :{totalStatistic?.delivered}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Cancel :{totalStatistic?.cancel}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Return  :{totalStatistic?.total_return_order}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          On Hold :{totalStatistic?.onHold}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Out For Delivery :{totalStatistic?.outForDelivery}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Pending :{totalStatistic?.pending}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Processing :{totalStatistic?.processing}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return :{totalStatistic?.return}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return Delivered :{totalStatistic?.return_delivered}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return On Hold :{totalStatistic?.return_onHold}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return Out delivery :{totalStatistic?.return_outForDelivery}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return processing :{totalStatistic?.return_processing}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          In Transit :{totalStatistic?.in_transit}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Return In Tranist :{totalStatistic?.return_in_transit}
        </Card.Grid>
      </Card>

      <Card title="Last Month Statistic">
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Order :{lastmonthStatistic?.total_order}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Delivered :{lastmonthStatistic?.delivered}
        </Card.Grid>
        <Card.Grid
          style={{
            width: "20%",
            textAlign: "center",
            color:'white',backgroundColor: "#b91e1e",
          }}
        >
          Total Cancel :{lastmonthStatistic?.cancel}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Total Return  :{lastmonthStatistic?.total_return_order}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          On Hold :{lastmonthStatistic?.onHold}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Out For Delivery :{lastmonthStatistic?.outForDelivery}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Total Pending :{lastmonthStatistic?.pending}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Total Processing :{lastmonthStatistic?.processing}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return :{lastmonthStatistic?.return}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return Delivered :{lastmonthStatistic?.return_delivered}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return On Hold :{lastmonthStatistic?.return_onHold}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return Out delivery :{lastmonthStatistic?.return_outForDelivery}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return processing :{lastmonthStatistic?.return_processing}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          In Transit :{lastmonthStatistic?.in_transit}
        </Card.Grid>
        <Card.Grid
          style={{ width: "20%", textAlign: "center", color:'white',backgroundColor: "#b91e1e" }}
        >
          Return In Tranist :{lastmonthStatistic?.return_in_transit}
        </Card.Grid>
      </Card>
    </>
  );

  return (
    <Content>
      {Pageheader}
      {Total_statistic}
      {Main_chart}

      {AllOrderstatistic}
    </Content>
  );
};

export default connect(null, {
  ParcelStatistics,
})(ParcelDashboard);
