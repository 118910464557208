import React, { useEffect } from "react";
import {
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Empty,
  Timeline,
  Button,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";

import {
  CheckCircleOutlined,
  CheckSquareFilled,
  EditOutlined,
  EyeOutlined,
  HighlightOutlined,
  KeyOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  setShopDetails,
  shopAuthUpdate,
  fetchShopEditData,
  geteditShopUpdate,updateShopPayment
} from "../../../actions/shop";
import { getAreas ,getAreasForShop} from "../../../actions/area";
import {authnticateShop} from "../../../actions/merchant";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import { Title, Wrapper } from "../../../Style";
import moment from "moment";
import history from "../../../history";
import SelectBox from "../../../components/select";
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
// const tailLayout = {
//   wrapperCol: { offset: 4, span: 16 },
// };
const comapny = [
  { value: "individual", label: "Person" },
  { value: "company", label: "Company" },
];

const ShopDetails = ({
  location,
  getAreas,getAreasForShop,
  setShopDetails,
  fetchShopEditData,
  shopAuthUpdate,authnticateShop,
  geteditShopUpdate,updateShopPayment
}) => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [remark, setRemark] = useState(false);
  const [remarkaccount, setRemarkAccount] = useState(false);
  const [editshop, setEditShop] = useState(false);
  const [editpayment, setEditPayment] = useState(false);
  const [auth, setAuth] = useState(false);
  const [custom, setCustom] = useState(false);
  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setSubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [coverageArea, setCoverageArea] = useState(null);
  const [pt, setPaymentType] = useState("");
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [visibleC, setVisibleC] = useState(false);
  const [visible, setVisible] = useState(false);
  const { Option } = Select;

  const pickup_type = [
    { value: "Electronics", label: "Electronics" },
    { value: "Health & Beauty", label: "Health & Beauty" },
    { value: "Jewelry", label: "Jewelry" },
    { value: "Clothing", label: "Clothing" },
    { value: "Craft", label: "Craft" },
    { value: "Fashion Accessories", label: "Fashion Accessories" },
    { value: "Fragile", label: "Fragile" },
    { value: "Groceries", label: "Groceries" },
    { value: "Others", label: "Others" },
  ];

  const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
  const account_type = [
    { value: "Saving", label: "Saving" },
    { value: "Current", label: "Current" },
  ];
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];

  const active = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];

  const authenticated = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const merchant = [
    { value: "Company", label: "Company" },
    { value: "Individual", label: "Individual" },
  ];
  const doc = [
    { value: "NID", label: "NID" },
    { value: "Passport", label: "Passport" },
    { value: "Driving_Licence", label: "Driving Licence" },
    { value: "None", label: "None" },
  ];
  const merchant_mobile = new URLSearchParams(location.search).get("merchant");
  const shop_id = new URLSearchParams(location.search).get("shop_id");
  async function fetchData() {
    const mobile = new URLSearchParams(location.search).get("merchant");
    const id = new URLSearchParams(location.search).get("shop_id");

    const res = await setShopDetails(mobile, id);
    setData(res);
  }
  const fetchEditdata = async () => {
    const mobile = new URLSearchParams(location.search).get("merchant");
    const id = new URLSearchParams(location.search).get("shop_id");

    const area = await getAreasForShop();
    setCoverageArea(area);

    const res = await fetchShopEditData(mobile, id);
    setCustom(res?.is_custom)
    const splitAreaCode = res?.pickup_area_code?.split("-");

    setCity(splitAreaCode[0]);
    setSubCity(splitAreaCode[1]);
    setThana(splitAreaCode[2]);
    setArea(splitAreaCode[3]);

    setSubCityArr(area?.city[splitAreaCode[0] - 1]?.sub_city);

    setThanaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana
    );

    setAreaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana[
        splitAreaCode[2] - 1
      ].area
    );

    form.setFieldsValue({
      shop_id: res?.shop_id,
      merchant: mobile,
      name: res?.name,
      email: res?.email,
      address: res?.address,
      mobile_number: res?.mobile_number,
      trade_license: res?.trade_license,
      pickup_address: res?.pickup_address,
      pickup_type: res?.pickup_type,
      cod: res?.cod ? res.cod : "",
      is_custom: res?.is_custom,
      payment_method: res?.payment_info?.payment_method,
      account_name: res?.payment_info?.account_name,
      account_number: res?.payment_info?.account_number,
      account_type: res?.payment_info?.account_type,
      bank_name: res?.payment_info?.bank_name,
      branch_name: res?.payment_info?.branch_name,
      routing_number: res?.payment_info?.routing_number,
      mobile_bank: res?.payment_info?.mobile_bank,
      mobile_banking_account: res?.payment_info?.mobile_banking_account,

      d_in_city: res?.delivery_charge?.in_city,
      d_in_sub_city: res?.delivery_charge?.in_sub_city,
      d_outer_city: res?.delivery_charge?.outer_city,
      d_ODD: res?.delivery_charge?.ODD,

      r_in_city: res?.return_charge?.in_city,
      r_in_sub_city: res?.return_charge?.in_sub_city,
      r_outer_city: res?.return_charge?.outer_city,
      r_ODD: res?.return_charge?.ODD,

      base_in_city: res?.base_weight?.in_city,
      base_in_sub_city: res?.base_weight?.in_sub_city,
      base_outer_city: res?.base_weight?.outer_city,
      base_ODD: res?.base_weight?.ODD,

      ecpk_in_city: res?.ECPK?.in_city,
      ecpk_in_sub_city: res?.ECPK?.in_sub_city,
      ecpk_outer_city: res?.ECPK?.outer_city,
      ecpk_ODD: res?.ECPK?.ODD,

      cod_in_city: res?.cod?.in_city,
      cod_in_sub_city: res?.cod?.in_sub_city,
      cod_outer_city: res?.cod?.outer_city,
      cod_ODD: res?.cod?.ODD,

      city: splitAreaCode[0],
      subCity: splitAreaCode[1],
      thana: splitAreaCode[2],
      area: splitAreaCode[3],
      pickup_area_code: city + "-" + subCity + "-" + thana + "-" + area,
    });
    forms.setFieldsValue({
      
      payment_method: res?.payment_info?.payment_method,
      account_name: res?.payment_info?.account_name,
      account_number: res?.payment_info?.account_number,
      account_type: res?.payment_info?.account_type,
      bank_name: res?.payment_info?.bank_name,
      branch_name: res?.payment_info?.branch_name,
      routing_number: res?.payment_info?.routing_number,
      mobile_bank: res?.payment_info?.mobile_bank,
      mobile_banking_account: res?.payment_info?.mobile_banking_account,

      
    });

    setData1(res);
    setPaymentType(res?.payment_info?.payment_method);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);

    fetchData();
    fetchEditdata();
  }, []);

  const handleVisible = async (mobile, shop_id) => {
    history.push(`/shop-details?merchant=${mobile}&shop_id=${shop_id}`);
  };

  const handleRefresh = async () => {
    const res = await setShopDetails();
    setData(res);
  };

  const handleMercahantAuth = async (values) => {
    console.log(data?.merchant?.mobile_number,data?.shop?.shop_id,values)
    const code = await shopAuthUpdate(data?.merchant?.mobile_number,data?.shop?.shop_id,values);

    // console.log('code',code)
    if (code === 201) {
      const res = await setShopDetails(data?.merchant?.mobile_number,data?.shop?.shop_id);
      setData(res);
    }
  };
  const onFinishEdit = async (values) => {
    
    const code = await geteditShopUpdate(values, custom);
    if (code === 200) {
      setEditShop(false);
    }
  };
  const onFinishEditPayment = async (values) => {
    
    const code = await updateShopPayment(merchant_mobile,shop_id,values);
    if (code === 200) {
      setEditPayment(false);
    }
  };
  const handleokPayment = () => {
    setEditPayment(false);
  };
  const handleOkEdit = () => {
    setEditShop(false);
  };
  const handleCancelPayment = () => {
    setEditPayment(false);
  };
  const handleCancelEdit = () => {
    setEditShop(false);
  };
  const onFinishFailedEdit = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };
  const handleMercahantACtive = async (mobile_number, active) => {
    const code = await shopAuthUpdate(mobile_number, active);
    if (code === 201) {
      const res = await setShopDetails();
      setData(res);
    }
  };

  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setSubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: '',
      thana: '',
      area: '',
     
  });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
    
      thana: '',
      area: '',
     
  });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      
      area: '',
     
  });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);

  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) => {
          return item?.active ? (
            <Option key={`${id}`} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) => {
          return item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Shop Details"
      subTitle="Merchant Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              setEditShop(true);
             
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<HighlightOutlined />}
            onClick={() => {
              setEditPayment(true);
             
            }}
          >
          Add/Edit Payment 
          </Button>
        </>,
      ]}
    ></PageHeader>
  );
  const merchantdetails = (
    <Wrapper>
      {data ? (
        <>
          <Title>Shop Information </Title>
          <br />
          <Descriptions>
            <Descriptions.Item label="Name">
              <strong>{data?.shop?.name}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <strong>{data?.shop?.email}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Balance">
              <strong style={{ color: "blue" }}>
                {data?.shop?.account_balance}
              </strong>
            </Descriptions.Item>
            <Descriptions.Item label="Mobile Number">
              <strong>{data?.shop?.mobile_number}</strong>
            </Descriptions.Item>

            <Descriptions.Item label="Trade license">
              <strong>{data?.shop?.trade_license}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Pickup Address">
              <strong>{data?.shop?.pickup_address}</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Pickup Type">
              <strong>{data?.shop?.pickup_type}</strong>
            </Descriptions.Item>

            <Descriptions.Item label="Created at">
              <strong>
                {moment
                  .utc(data?.shop?.created_at)
                  .format("YYYY-MM-DD hh:mm A")}
                &nbsp; {""}
              </strong>
            </Descriptions.Item>

            <Descriptions.Item label="">
              {data?.shop?.authenticated === false ? (
                <>
                  <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleMercahantAuth( true)
                        }
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<StopOutlined />}
                        >
                          Unauthenticated
                        </Button>
                      </Popconfirm>
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                  >
                    Authenticated
                  </Button>
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="">
              {data?.shop?.active === false ? (
                <Button
                  size="small"
                  type="primary"
                  icon={<CheckCircleOutlined />}
                >
                  Inactive
                </Button>
              ) : (
                <Button
                  size="small"
                  type="primary"
                  icon={<CheckCircleOutlined />}
                >
                  Actived
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>

          <br />
          {data?.shop?.payment_info ? (
            <>
              <Title>Payment Information </Title>
              <br />
              {data?.shop?.payment_info?.payment_method === "Mobile Banking" ? (
                <Descriptions>
                  <Descriptions.Item label="Payment Method">
                    <strong>{data?.shop?.payment_info?.mobile_bank}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Account Type">
                    <strong>{data?.shop?.payment_info?.mobile_bank}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Account No">
                    <strong>
                      {data?.shop?.payment_info?.mobile_banking_account}
                    </strong>
                  </Descriptions.Item>
                </Descriptions>
              ) : (
                <Descriptions>
                  <Descriptions.Item label="Payment Method">
                    <strong>{data?.shop?.payment_info?.payment_method}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Bank Name">
                    <strong>{data?.shop?.payment_info?.bank_name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Brance Name">
                    <strong>{data?.shop?.payment_info?.branch_name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Account No">
                    <strong>{data?.shop?.payment_info?.account_number}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Account Name">
                    <strong>{data?.shop?.payment_info?.account_name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Routing Number">
                    <strong>{data?.shop?.payment_info?.routing_number}</strong>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </>
          ) : null}
          {data?.shop?.delivery_charge ? (
            <>
              <Title>Delivery Charge Information </Title>
              <br />
              <Descriptions>
                <Descriptions.Item label="In City">
                  <strong>{data?.shop?.delivery_charge?.in_city}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Sub City">
                  <strong>{data?.shop?.delivery_charge?.in_sub_city}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Outer City">
                  <strong>{data?.shop?.delivery_charge?.outer_city}</strong>
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : null}
          {data?.shop?.return_charge ? (
            <>
              <Title>Return Charge Information </Title>
              <br />
              <Descriptions>
                <Descriptions.Item label="In City">
                  <strong>{data?.shop?.return_charge?.in_city}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Sub City">
                  <strong>{data?.shop?.return_charge?.in_sub_city}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Outer City">
                  <strong>{data?.shop?.return_charge?.outer_city}</strong>
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : null}

          {data ? (
            <>
              <Title>Merchant Information </Title>
              <br />
              <Descriptions>
                <Descriptions.Item label="Name">
                  <strong>{data?.merchant?.name}</strong>
                </Descriptions.Item>
                <Descriptions.Item label="Mobile Number">
                  <strong>{data?.merchant?.mobile_number}</strong>
                </Descriptions.Item>

                <Descriptions.Item label="Company Name">
                  <strong>{data?.merchant?.company_name}</strong>
                </Descriptions.Item>

                <Descriptions.Item label="Address">
                  <strong>{data?.merchant?.address}</strong>
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : null}

          <br />
          {data.shop?.logs ? (
            <>
              <Title>Logs </Title>
              <br />
              {data?.shop?.logs ? (
                <Timeline mode="left">
                  {data.shop?.logs.map((item, index) => (
                    <Timeline.Item
                      label={moment
                        .utc(item?.created_at)
                        .format("YYYY-MM-DD hh:mm A")}
                      keys={index}
                    >
                      {item?.message}
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                <Empty />
              )}
            </>
          ) : null}
          <br />
        </>
      ) : null}
    </Wrapper>
  );
  const EditShopDetails = (
    <>
      <Modal
        title="Shop edit"
        centered
        visible={editshop}
        cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelEdit}
        width={600}
      >
        {data1 ? (
          <>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinishEdit}
              onFinishFailed={onFinishFailedEdit}
              form={form}
            >
              {renderInput("merchant", "Merchant Number", [
                { required: true, message: "Field is required" },
              ])}
              <Divider style={{ color: "#b91e1e" }}>
                Merchant varified. Please fill the form
              </Divider>
              <Form.Item label="User ID" hidden={true} name="shop_id">
                {/* <Input size="large" type="hidden"  /> */}
              </Form.Item>
              {renderInput("name", "Shop Name", [
                { required: true, message: "Shop name is required" },
              ])}
              {renderInput("email", "Email ", [
                { required: true, message: "field is required" },
              ])}
              {renderInput("mobile_number", "Mobile Number", [
                { required: true, message: "Mobile Number is required" },
              ])}
              {renderTextField("pickup_address", " Pickup Address ", [
                { required: true, message: "Field is required" },
              ])}
              {renderTextField("address", "Address ", [
                { required: true, message: "Field is required" },
              ])}

              {renderInput("trade_license", "Trade Licence ", [
                { required: false, message: "Field is required" },
              ])}

              {renderSelect(
                "pickup_type",
                "Pickup Type",
                [{ required: true }],
                "Select One",
                pickup_type,
                "none"
              )}

              <Divider>Select Area</Divider>

              {/* ================ City =============== */}

              <Form.Item
                name="city"
                label={"Select City"}
                rules={[
                  {
                    required: true,
                    message: "City  is required",
                  },
                ]}
              >
                <Select showSearch
                  placeholder={"Select one city"}
                  onChange={(e) => city_(e)}
                  value={city}
                >
                  <Option value="-1">Select City</Option>
                  {c_o}
                </Select>
              </Form.Item>
              {/* ================ Sub City ============ */}
              {subCityArr ? (
                <Form.Item
                  name="subCity"
                  label={"Select Subcity"}
                  rules={[
                    {
                      required: true,
                      message: "Subcity is required",
                    },
                  ]}
                >
                  <Select showSearch
                    placeholder={"Select one subcity"}
                    onChange={(e) => sub_city(e)}
                    value={subCity}
                  >
                    {/* {options} */}
                    {s_o}
                  </Select>
                </Form.Item>
              ) : null}

              {/* ============== Thana ============== */}
              {thanaArr ? (
                <Form.Item
                  name="thana"
                  label={"Select one thana"}
                  rules={[
                    {
                      required: true,
                      message: "Thana is required",
                    },
                  ]}
                >
                  <Select showSearch
                    placeholder={"Select one subcity"}
                    onChange={(e) => thana_(e)}
                    value={thana}
                  >
                    {/* {options} */}
                    {t_o}
                  </Select>
                </Form.Item>
              ) : null}
              {/* ============== Area ============= */}
              {areaArr ? (
                <Form.Item
                  name="area"
                  label={"Select one area"}
                  rules={[
                    {
                      required: true,
                      message: "Area is required",
                    },
                  ]}
                >
                  <Select showSearch
                    placeholder={"Select one area"}
                    onChange={(e) => area_(e)}
                    value={area}
                  >
                    {/* {options} */}
                    {a_o}
                  </Select>
                </Form.Item>
              ) : null}

              <Form.Item
                name="is_custom"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
                //defaultValue={false}
              >
                <Checkbox onChange={(e) => setCustom(e.target.checked)}>
                  Select Customized Shop Charge
                </Checkbox>
              </Form.Item>

              {custom  ? (
                <>
                  <Divider>Delivery Charge</Divider>
                  <Row gutter={16}>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("d_in_city", "In City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("d_in_sub_city", "Sub City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("d_outer_city", "Outer City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("d_ODD", "ODD ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                  </Row>

                  <Divider>Return Charge</Divider>
                  <Row gutter={16}>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("r_in_city", "In City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("r_in_sub_city", "Sub City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("r_outer_city", "Outer City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("r_ODD", "ODD ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                  </Row>
                  <Divider>Base Weight (kg)</Divider>
                  <Row gutter={16}>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("base_in_city", "In City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("base_in_sub_city", "Sub City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("base_outer_city", "Outer City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("base_ODD", "ODD ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                  </Row>
                  <Divider>ECPK</Divider>
                  <Row gutter={16}>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("ecpk_in_city", "In City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("ecpk_in_sub_city", "Sub City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("ecpk_outer_city", "Outer City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("ecpk_ODD", "ODD ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                  </Row>
                  <Divider>COD</Divider>
                  <Row gutter={16}>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("cod_in_city", "In City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("cod_in_sub_city", "Sub City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("cod_outer_city", "Outer City ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                    <Col xs={12} lg={12} sm={24} md={12}>
                      {renderInput("cod_ODD", "ODD ", [
                        { required: true, message: "field is required" },
                      ])}
                    </Col>
                  </Row>
                </>
              ) : null}

              
              {renderTextField("remark", "Remark", [
                { required: true, message: "Field is required" },
              ])}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : null}
      </Modal>

      <Modal
        title="Edit Payment"
        centered
        visible={editpayment}
        cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancelPayment}
        width={600}
      >
        {data1 ? (
          <>
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinishEditPayment}
              onFinishFailed={onFinishFailedEdit}
              form={forms}
            >
            
              <SelectBox
                name="payment_method"
                label="Payment Method"
                rules={[
                  { required: true, message: "payment type is required" },
                ]}
                options={payment_method}
                placeholder="Select One"
                onChange={(e) => {
                  setPaymentType(e);
                }}
              />
              {pt === "Mobile Banking" ? (
                <>
                  {renderSelect(
                    "mobile_bank",
                    "Mobile Banking Name*",
                    [{ required: true }],
                    "Select One",
                    mobile_type,
                    "none"
                  )}
                  {renderInput("mobile_banking_account", "Mobile Number ", [
                    { required: true, message: "number is required" },
                  ])}

                  <br></br>
                </>
              ) : null}
              {pt === "Bank Account" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}
                  {renderSelect(
                    "account_type",
                    "Accont Type*",
                    [{ required: true }],
                    "Select One",
                    account_type,
                    "none"
                  )}
                  {renderInput("account_name", "Account Name", [
                    { required: true, message: "name is required" },
                  ])}
                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "name is required" },
                  ])}
                  {renderInput("account_number", "Account Number ", [
                    { required: true, message: "number is required" },
                  ])}
                  {renderInput("routing_number", "Routing Number", [
                    { required: true, message: "number is required" },
                  ])}
                </>
              ) : null}
              {renderTextField("remark", "Remark", [
                { required: true, message: "Field is required" },
              ])}

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : null}
      </Modal>
    </>
  );

  return (
    <Content>
      {pageheader}
      <br></br>
      {merchantdetails}
      <br></br>
      {EditShopDetails}
    </Content>
  );
};

export default connect(null, {
  setShopDetails,
  shopAuthUpdate,
  fetchShopEditData,
  geteditShopUpdate,
  getAreas,getAreasForShop,authnticateShop,updateShopPayment
})(ShopDetails);
